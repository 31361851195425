import {
  Integration,
  NotificationEmail,
  NotificationSlack,
} from "../../../../../../services/cloudchipr.api";
import { IntegrationNotification } from "../types/types";
import { IntegrationType } from "../../../../../../store/integrations/integrationsSlice";

export const generateIntegrationMetadata = (
  type: IntegrationType,
  integration?: Integration,
): IntegrationNotification => {
  const conversation =
    integration?.conversation ?? integration?.metadata?.conversation;
  const slackConversations = conversation ? [conversation] : undefined;
  const metadataEmails = integration?.emails ?? integration?.metadata?.emails;

  const integrationMetadata = {
    integration_id: integration?.id,
    type: type,
  } as IntegrationNotification;

  if (type === "slack" && slackConversations) {
    (integrationMetadata as NotificationSlack).conversations =
      slackConversations;
  } else if (type === "email" && metadataEmails) {
    (integrationMetadata as NotificationEmail).to = metadataEmails;
  }

  return integrationMetadata;
};
