import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentTaskViewsHierarchyApiArg,
} from "../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getTasksViewsHierarchyThunk } from "../getTasksViewsHierarchyThunk";

type ReorderTasksViewThunkArg = Omit<
  PatchUsersMeOrganisationsCurrentTaskViewsHierarchyApiArg["body"],
  "index"
> & {
  index: number;
};

export const reorderTasksViewThunk = createAsyncThunk(
  "taskManagement/reorderTasksView",
  async ({ id, index }: ReorderTasksViewThunkArg, { dispatch }) => {
    const { patchUsersMeOrganisationsCurrentTaskViewsHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentTaskViewsHierarchy.initiate({
          body: {
            id,
            index,
            type: "view",
          },
        }),
      ).unwrap();

      if (!response) {
        return;
      }

      await dispatch(getTasksViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
