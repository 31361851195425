import { FC, Fragment, useCallback } from "react";
import { Menu, PopoverOrigin } from "@mui/material";
import { Rule } from "@mui/icons-material";
import { FloatingActionButton } from "./FloatingActionButton";
import { FloatingPropertyActionItem } from "./property-action/FloatingPropertyActionItem";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { taskAvailablePropertiesSelector } from "../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { useAppAbility } from "../../../../../../services/permissions";
import { taskPropertyTitle } from "../../../utils/constants/taskPropertyTitle";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { TaskPropertySelectionEditItem } from "../../../../common/task-management/components/common/properties/task-property-selection/TaskPropertySelectionEditItem";
import { taskCurrentViewConfigShowCompletedSelector } from "../../../../../../store/task-management/selectors/taskCurrentViewConfigShowCompletedSelector";
import { setTaskManagementSelectedTasks } from "../../../../../../store/task-management/taskManagementSlice";

export const FloatingStatusActionButton: FC = () => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const availableProperties = useAppSelector(taskAvailablePropertiesSelector);
  const options = availableProperties?.["statuses"];

  const showCompleted = useAppSelector(
    taskCurrentViewConfigShowCompletedSelector,
  );

  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const itemClickHandler = useCallback(
    async (id: string | null) => {
      const selectedOption = options?.find((option) => option.id === id);
      closeMenu();

      await dispatch(
        taskManagementUpdateSelectedTasksThunk({
          status_id: id,
        }),
      );

      if (!showCompleted && selectedOption?.category === "completed") {
        dispatch(setTaskManagementSelectedTasks([]));
      }
    },
    [dispatch, closeMenu, showCompleted, options],
  );

  return (
    <Fragment>
      <FloatingActionButton
        onClick={openMenu}
        disabled={!canEditTask}
        icon={Rule}
      >
        {taskPropertyTitle.get("statuses")}
      </FloatingActionButton>

      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={slotProps}
        MenuListProps={menuListProps}
      >
        {options?.map((option) => (
          <FloatingPropertyActionItem
            key={option.id}
            id={option.id}
            name={option.name}
            color={option.color}
            onClick={itemClickHandler}
          />
        ))}

        <TaskPropertySelectionEditItem propertyType="statuses" />
      </Menu>
    </Fragment>
  );
};

const slotProps = {
  paper: { sx: { mt: -2 } },
};

const menuListProps = { sx: { pb: 0 } };

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
