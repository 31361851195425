import { FC, useCallback } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../../store/hooks";
import { setActiveIntegration } from "../../../../../store/integrations/integrationsSlice";

interface SlackConversationsDropdownFooterProps {
  integrationId: string;
  onClose?: () => void;
}

export const SlackConversationsDropdownFooter: FC<
  SlackConversationsDropdownFooterProps
> = ({ integrationId, onClose }) => {
  const dispatch = useAppDispatch();

  const openEditIntegrationDrawer = useCallback(() => {
    onClose?.();
    dispatch(setActiveIntegration({ type: "slack", id: integrationId }));
  }, [dispatch, integrationId, onClose]);

  return (
    <Stack alignItems="center" p={2} spacing={1}>
      <Button onClick={openEditIntegrationDrawer}>
        Manage Conversations List
      </Button>

      <Typography color="text.secondary" variant="caption">
        If you can’t find your preferred conversation, choose to manage the
        list.
      </Typography>
    </Stack>
  );
};
