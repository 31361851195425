import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState,
  KeyboardEvent,
  MouseEvent,
} from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Divider, Menu, Stack, TextField } from "@mui/material";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "rooks";
import { ViewsHierarchyMoreTabsDropdownLabel } from "./ViewsHierarchyMoreTabsDropdownLabel";
import { useViewsHierarchyContext } from "../ViewsHierarchyProvider";
import { View } from "../../../../../../services/cloudchipr.api";

interface ViewsHierarchyMoreTabsDropdownProps {
  selectedViewId: string;
  hiddenViewsCount: number;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyMoreTabsDropdown: FC<
  ViewsHierarchyMoreTabsDropdownProps
> = ({ onTabChange, selectedViewId, hiddenViewsCount }) => {
  const {
    allItemsLabel,
    views,
    actions: {
      viewReorder: { onReorder },
    },
  } = useViewsHierarchyContext();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const [keyword, setKeyword] = useState("");
  const [fixedPosition, setFixedPosition] = useState<{
    top: number;
    left: number;
  }>();

  const showAllItems =
    !keyword || allItemsLabel.toLowerCase().includes(keyword);

  const ids = useMemo(() => views.map((view) => view.id), [views]);
  const searchViews = useMemo(() => {
    if (!keyword) {
      return views;
    }

    return views.filter((view) => view.name.toLowerCase().includes(keyword));
  }, [views, keyword]);

  const reorderRow = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over) {
        return;
      }

      if (active.id !== over.id) {
        const oldIndex = views.findIndex(({ id }) => id === active.id);
        const newIndex = views.findIndex(({ id }) => id === over.id);
        onReorder(String(active.id), newIndex, oldIndex);
      }
    },
    [views, onReorder],
  );

  const searchKeywordChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const keyword = e.target.value.toLowerCase().trim();
      setKeyword(keyword);
    },
    [],
  );
  const searchKeywordChangeHandlerDebounced = useDebounce(
    searchKeywordChangeHandler,
    200,
  );

  const closeMenu = useCallback(() => {
    setFixedPosition(undefined);
  }, []);

  const onClose = useCallback(() => {
    closeMenu();
    setKeyword("");
  }, [closeMenu]);

  const clickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setFixedPosition({ top: rect.bottom, left: rect.left });
  }, []);

  if (!views) {
    return;
  }

  return (
    <Fragment>
      <Button
        variant="text"
        size="small"
        onClick={clickHandler}
        sx={buttonStyles}
      >
        {`${hiddenViewsCount} more...`}
      </Button>

      <Menu
        open={!!fixedPosition}
        onClose={onClose}
        slotProps={slotProps}
        MenuListProps={menuListProps}
        anchorReference="anchorPosition"
        anchorPosition={fixedPosition}
      >
        <Stack p={1.5}>
          <TextField
            autoFocus
            size="xsmall"
            variant="outlined"
            placeholder="Search..."
            onChange={searchKeywordChangeHandlerDebounced}
            onKeyDown={stopPropagation}
            onKeyUp={stopPropagation}
            InputProps={inputProps}
          />
        </Stack>

        <Divider />

        {showAllItems && (
          <ViewsHierarchyMoreTabsDropdownLabel
            locked
            view={{ id: "", name: allItemsLabel } as View}
            onTabChange={onTabChange}
            onClose={closeMenu}
          />
        )}

        <DndContext
          sensors={sensors}
          onDragEnd={reorderRow}
          modifiers={modifies}
          collisionDetection={closestCenter}
        >
          <SortableContext items={ids} strategy={verticalListSortingStrategy}>
            {searchViews.map((view) => (
              <ViewsHierarchyMoreTabsDropdownLabel
                key={view.id}
                view={view}
                locked={!!keyword}
                onClose={closeMenu}
                selected={selectedViewId === view.id}
                onTabChange={onTabChange}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Menu>
    </Fragment>
  );
};

const stopPropagation = (e: KeyboardEvent<HTMLDivElement>) =>
  e.stopPropagation();

const inputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  ),
};

const modifies = [
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToFirstScrollableAncestor,
];

const slotProps = {
  paper: {
    sx: {
      overflow: "auto",
      width: 250,
    },
  },
};

const menuListProps = { sx: { pt: 0 } };

const buttonStyles = {
  ml: 1,
  color: "text.primary",
  fontWeight: "medium",
  textTransform: "none",
  whiteSpace: "nowrap",
};
