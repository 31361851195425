import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ResourceExplorerGroupingNullable,
} from "../../../../services/cloudchipr.api";
import { resourceDetailsCostsDatesSelector } from "../../selectors/options/resourceDetailsCostsDatesSelector";
import { resourceDetailsCostsDataPointSelector } from "../../selectors/options/resourceDetailsCostsDataPointSelector";
import { resourceDetailsTargetResourceSelector } from "../../selectors/target-resource/resourceDetailsTargetResourceSelector";
import { getResourceDetailsResourceBillingDataFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

type ThunkArgs = {
  groupBy: ResourceExplorerGroupingNullable | null;
  processKey: string;
};

export const getResourceDetailsResourceBillingGroupedDataThunk =
  createAsyncThunk(
    "resourceDetails/getResourceDetailsResourceBillingGroupedDataThunk",
    async ({ groupBy, processKey }: ThunkArgs, { dispatch, getState }) => {
      const state = getState() as RootState;

      const dates = resourceDetailsCostsDatesSelector(state);
      const dataPoint = resourceDetailsCostsDataPointSelector(state);
      const resource = resourceDetailsTargetResourceSelector(state);

      const { postUsersMeOrganisationsCurrentResourceExplorer } =
        cloudChiprApi.endpoints;

      if (!resource) {
        return;
      }

      return await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorer.initiate(
          {
            resourceExplorerFilterSchema: {
              group_by: groupBy,
              data_point: dataPoint,
              dates,
              filter_tree: {
                node_type: "group",
                operator: "and",
                items: [
                  {
                    node_type: "item",
                    type: "resource_id",
                    filter_provider: resource.provider,
                    value: [resource.billing_explorer_resource_identifier],
                    operator: "in",
                  },
                ],
              },
            },
          },
          {
            fixedCacheKey:
              getResourceDetailsResourceBillingDataFixedCacheKey(processKey),
          },
        ),
      ).unwrap();
    },
  );
