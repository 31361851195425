import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ResourceExplorerFilterTreeActions } from "./ResourceExplorerFilterTreeActions";
import { FilterTree } from "../../../../../../../common/filters-tree/components/FilterTree";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";
import { useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery } from "../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerFilterTreeSelector } from "../../../../../../../../../store/resource-explorer/selectors/filter-tree/resourceExplorerFilterTreeSelector";
import { setResourceExplorerFilterTree } from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { getResourceExplorerWidgetDataThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerWidgetDataThunk";
import { calculateFilterTreeFiltersCount } from "../../../../../../../common/filters-tree/utils/helpers/calculateFilterTreeFiltersCount";
import { removeFiltersWithEmptyValues } from "../../../../../../../common/filters-tree/utils/helpers/removeFiltersWithEmptyValues";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { filterTreeFiltersAreInvalid } from "../../../../../../../common/filters-tree/utils/helpers/filterTreeFiltersAreInvalid";

export const ResourceExplorerFilterTree: FC = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery({});

  const { open, closeMenu, anchor, openMenu } = useMenuHook();
  const filterTree = useAppSelector(resourceExplorerFilterTreeSelector);
  const [filterTreeLocal, setFilterTreeLocal] = useState(filterTree);

  const filtersCount = useMemo(
    () => calculateFilterTreeFiltersCount(filterTreeLocal),
    [filterTreeLocal],
  );

  const applyHandler = useCallback(() => {
    closeMenu();

    if (!filterTreeLocal) {
      return;
    }

    const cleanFilters = removeFiltersWithEmptyValues(filterTreeLocal);
    dispatch(setResourceExplorerFilterTree(cleanFilters));

    if (filterTree && isDeepEqual(cleanFilters, filterTree)) {
      return;
    }

    dispatch(getResourceExplorerWidgetDataThunk());
  }, [dispatch, closeMenu, filterTreeLocal, filterTree]);

  const cancelHandler = useCallback(() => {
    setFilterTreeLocal(filterTree);
    closeMenu();
  }, [closeMenu, filterTree]);

  const closeHandler = useCallback(() => {
    const validForApply =
      filterTreeLocal && !filterTreeFiltersAreInvalid(filterTreeLocal);

    if (validForApply) {
      applyHandler();
    } else {
      setFilterTreeLocal(filterTree);
    }

    closeMenu();
  }, [closeMenu, applyHandler, filterTreeLocal, filterTree]);

  const revertFiltersHandler = useCallback(() => {
    setFilterTreeLocal(filterTree);
  }, [filterTree]);

  useEffect(() => {
    setFilterTreeLocal(filterTree);
  }, [filterTree]);

  return (
    <Fragment>
      <Button
        size="small"
        onClick={openMenu}
        variant="outlined"
        disabled={isLoading}
        startIcon={<FilterAltOutlinedIcon />}
        sx={{
          lineHeight: 0,
          bgcolor: open || filtersCount ? "primary.light" : undefined,
        }}
      >
        Filters{!!filtersCount && ` (${filtersCount})`}
      </Button>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeHandler}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box p={2} pb={0} width={800} maxHeight={1100}>
          <FilterTree
            showSelectOnFirstOpening
            withIncludeAllProviderAccountsOption
            possibleFilters={data ?? []}
            filterTree={filterTreeLocal}
            setFilters={setFilterTreeLocal}
            onRevertFilters={revertFiltersHandler}
          />

          <ResourceExplorerFilterTreeActions
            onApply={applyHandler}
            onCancel={cancelHandler}
            filterTree={filterTreeLocal}
          />
        </Box>
      </Popover>
    </Fragment>
  );
};
