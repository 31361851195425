import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Integration,
  ProviderType,
  ResourceType,
} from "../../services/cloudchipr.api";

export type IntegrationType = Integration["type"];
export type IntegrationTypeWithoutWebhook = Exclude<IntegrationType, "webhook">;

export interface ActiveIntegration {
  id: string | null;
  type: IntegrationType | null;
}

export interface IntegrationsSliceInterface {
  currentIntegrationType: IntegrationType | null;
  resourceType: ResourceType | null;
  provider?: ProviderType | null;
  activeIntegration: ActiveIntegration;
}

const initialState: IntegrationsSliceInterface = {
  currentIntegrationType: null,
  resourceType: null,
  provider: null,
  activeIntegration: {
    id: null,
    type: null,
  },
};

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: initialState,
  reducers: {
    initiateCurrentIntegration: (
      state,
      action: PayloadAction<{
        type: Integration["type"];
        resourceType: ResourceType;
        provider?: ProviderType | null;
      }>,
    ) => {
      state.currentIntegrationType = action.payload.type;
      state.resourceType = action.payload.resourceType;
      state.provider = action.payload.provider;
    },
    destroyCurrentIntegration: () => {
      return initialState;
    },
    setActiveIntegration: (state, action: PayloadAction<ActiveIntegration>) => {
      state.activeIntegration.id = action.payload.id;
      state.activeIntegration.type = action.payload.type;
    },
  },
});

export const {
  initiateCurrentIntegration,
  destroyCurrentIntegration,
  setActiveIntegration,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
