import { FC, useCallback, useMemo, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import {
  hierarchyViewIdSearchParamKey,
  viewTabMaxWidth,
} from "./utils/constants/constants";
import { ViewsHierarchyMoreTabsDropdown } from "./components/more-views-dropdown/ViewsHierarchyMoreTabsDropdown";
import { AddViewInHierarchyButton } from "./components/adding-view/AddViewInHierarchyButton";
import { ViewsHierarchyTabs } from "./components/ViewsHierarchyTabs";
import {
  useViewsHierarchyContext,
  ViewsHierarchyProvider,
  ViewsHierarchyProviderProps,
} from "./components/ViewsHierarchyProvider";
import { getDividedViews } from "./utils/helpers/getDividedViews";
import { NavigationExpander } from "../navigation-expander/NavigationExpander";

// the count of the items that should fit in the same container with views ( All Tab, DropdownButton, AddViewButton )
const notViewItemsCount = 2;

interface ViewsHierarchyComponentProps {
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyComponent: FC<ViewsHierarchyComponentProps> = ({
  onTabChange,
}) => {
  const [searchParams] = useSearchParams();
  const selectedViewId = searchParams.get("viewId") ?? "";
  const { pathname, views } = useViewsHierarchyContext();

  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [fittingViewsCount, setFittingViewsCount] = useState(0);

  const dividedViews = useMemo(
    () => getDividedViews(views, selectedViewId, fittingViewsCount),
    [fittingViewsCount, selectedViewId, views],
  );

  const tabChangeHandler = useCallback(
    (viewId: string) => {
      if (viewId === selectedViewId) {
        return;
      }

      if (!viewId) {
        navigate(pathname);
      } else {
        navigate(`${pathname}?${hierarchyViewIdSearchParamKey}=${viewId}`);
      }

      onTabChange(viewId);
    },
    [navigate, selectedViewId, onTabChange, pathname],
  );

  useEffectOnceWhen(() => {
    const intervalId = setInterval(() => {
      // as ref change will not update the component state, we need to check with interval when it is exists then calculate the fitting views count
      const width = wrapperRef.current?.clientWidth ?? 0;

      if (!width) {
        return;
      }

      const result = Math.floor(width / viewTabMaxWidth) - notViewItemsCount;

      setFittingViewsCount(Math.max(result, 1));
      clearInterval(intervalId);
    }, 300);
  }, !!views);

  const needToShowMoreButton = !!dividedViews.hiddenViews.length;

  return (
    <Box borderBottom={1} borderColor="grey.300">
      <Stack width="95%" spacing={2} direction="row" alignItems="center">
        <NavigationExpander />

        <Stack
          flex={1}
          direction="row"
          overflow="hidden"
          ref={wrapperRef}
          alignItems="center"
        >
          <ViewsHierarchyTabs
            views={dividedViews.visibleViews}
            onTabChange={tabChangeHandler}
            selectedViewId={selectedViewId}
          />

          {needToShowMoreButton && (
            <ViewsHierarchyMoreTabsDropdown
              onTabChange={tabChangeHandler}
              selectedViewId={selectedViewId}
              hiddenViewsCount={dividedViews.hiddenViews.length}
            />
          )}

          <AddViewInHierarchyButton />
        </Stack>
      </Stack>
    </Box>
  );
};

type ViewsHierarchyProps = ViewsHierarchyComponentProps &
  ViewsHierarchyProviderProps;

export const ViewsHierarchy: FC<ViewsHierarchyProps> = ({
  onTabChange,
  ...props
}) => {
  return (
    <ViewsHierarchyProvider {...props}>
      <ViewsHierarchyComponent onTabChange={onTabChange} />
    </ViewsHierarchyProvider>
  );
};
