import { FC } from "react";
import { Tooltip, TooltipProps } from "@mui/material";

export const TasksTooltipWithContent: FC<TooltipProps> = (props) => {
  const withContent = typeof props.title !== "string";

  return (
    <Tooltip
      {...props}
      componentsProps={
        withContent ? tooltipComponentsProps : props.componentsProps
      }
      placement="top"
      arrow
    />
  );
};

const tooltipComponentsProps = {
  tooltip: {
    sx: {
      color: "text.primary",
      bgcolor: "background.paper",
      "& .MuiTooltip-arrow": {
        color: "background.paper",
      },
      boxShadow: 7,
      p: 0,
    },
  },
};
