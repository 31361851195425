import { taskManagementTargetSelector } from "./target/common/taskManagementTargetSelector";
import { taskManagementTargetExistingTasksTargetIdsSelector } from "./target/common/taskManagementTargetExistingTasksTargetIdsSelector";
import { RootState } from "../../../store";
import { isTaskTargetResource } from "../../utils/helpers/isTaskTargetResource";

export const taskEditSaveButtonDisabledSelector = (state: RootState) => {
  const target = taskManagementTargetSelector(state);
  const existingTaskTargetIds =
    taskManagementTargetExistingTasksTargetIdsSelector(state);

  return isTaskTargetResource(target)
    ? target?.resources?.length === existingTaskTargetIds?.length
    : target?.opportunities?.length === existingTaskTargetIds.length;
};
