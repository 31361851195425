import { FC } from "react";
import { Alert, Button, Collapse, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useToggle } from "rooks";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { taskManagementTargetExistingTasksSelector } from "../../../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetExistingTasksSelector";
import { TaskStatusCell } from "../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/TaskStatusCell";
import { taskManagementTargetExistingTasksTargetIdsSelector } from "../../../../../../../../../store/task-management/selectors/form/target/common/taskManagementTargetExistingTasksTargetIdsSelector";

export const TaskCreationDuplicatedResourcesAlertMessage: FC = () => {
  const [collapsed, toggleCollapsed] = useToggle();

  const existingTasks = useAppSelector(
    taskManagementTargetExistingTasksSelector,
  );
  const targetIds = useAppSelector(
    taskManagementTargetExistingTasksTargetIdsSelector,
  );

  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!targetIds?.length || !existingTasks || editMode) {
    return null;
  }

  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        bgcolor: blue[100],
        fontWeight: "normal",
        color: "primary.main",
      }}
      action={
        <Button
          size="small"
          onClick={toggleCollapsed}
          sx={{ whiteSpace: "nowrap" }}
        >
          {collapsed ? "Hide" : "Show"}
        </Button>
      }
    >
      Tasks will not be created for the following {targetIds.length} resource
      {targetIds.length > 1 ? "s " : " "}
      because {targetIds.length > 1 ? "they" : "it"} already have task
      {targetIds.length > 1 ? "s " : " "} assigned.
      <Collapse in={collapsed}>
        <ul>
          {targetIds.map((id) => {
            const task = existingTasks?.[id];

            return (
              <li key={id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TypographyWithTooltip variant="body2" title={id} />
                  {task && <TaskStatusCell task={task} />}
                </Stack>
              </li>
            );
          })}
        </ul>
      </Collapse>
    </Alert>
  );
};
