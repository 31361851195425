import { FC, useCallback, useMemo } from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { emailNotificationId } from "../../utils/constants/constants";
import { IntegrationLogo } from "../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { CreateIntegrationButton } from "../integrations/CreateIntegrationButton";
import {
  Emails,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../services/cloudchipr.api";
import { IntegrationNotification } from "../../utils/types/types";
import EmailIcon from "../../../../../../assets/images/icons/email-icon/EmailIcon";
import { IntegrationType } from "../../../../../../store/integrations/integrationsSlice";

export interface EmailsSelectionIntegrationsSelectProps {
  disabledIntegrationTypes?: IntegrationType[];
  selectedIntegrations?: IntegrationNotification[] | null;

  onCreateDialogOpen(): void;
  emailsChangeHandler(emails: Emails | null): void;
  onAddNewIntegration(
    type: IntegrationType,
    integrationId: string,
    addToFirst?: boolean,
  ): void;
}

export const EmailsSelectionIntegrationsSelect: FC<
  EmailsSelectionIntegrationsSelectProps
> = ({
  emailsChangeHandler,
  selectedIntegrations,
  disabledIntegrationTypes,
  onAddNewIntegration,
  onCreateDialogOpen,
}) => {
  const { data } = useGetUsersMeIntegrationsQuery({});
  const options = useMemo(() => {
    return data?.filter(
      (integration) => !disabledIntegrationTypes?.includes(integration.type),
    );
  }, [data, disabledIntegrationTypes]);

  const changeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const id = event.target.value;

      const type = options?.find((integration) => integration.id === id)?.type;

      if (!type) {
        return;
      }

      emailsChangeHandler(null);
      onAddNewIntegration(type, id, true);
    },
    [options, onAddNewIntegration, emailsChangeHandler],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Select Notification Method</InputLabel>

      <Select
        onChange={changeHandler}
        label="Select Notification Method"
        value={emailNotificationId}
      >
        <MenuItem value={emailNotificationId}>
          <Stack direction="row" spacing={2}>
            <EmailIcon width={20} />

            <ListItemText>Email</ListItemText>
          </Stack>
        </MenuItem>

        <Divider />

        <ListSubheader sx={{ lineHeight: 1 }}>
          <Typography variant="caption" color="text.secondary">
            INTEGRATIONS
          </Typography>
        </ListSubheader>

        {options?.map((integration) => {
          return (
            <MenuItem
              key={integration.id}
              value={integration.id}
              disabled={selectedIntegrations?.some(
                ({ integration_id }) => integration_id === integration.id,
              )}
            >
              <Stack direction="row" spacing={2}>
                <IntegrationLogo
                  variant="colored"
                  type={integration.type}
                  width={20}
                />

                <ListItemText>{integration.name}</ListItemText>
              </Stack>
            </MenuItem>
          );
        })}

        <CreateIntegrationButton
          onClick={onCreateDialogOpen}
          empty={!options?.length}
        />
      </Select>
    </FormControl>
  );
};
