import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GridApi } from "ag-grid-community";
import { tasksCurrentViewSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewSelector";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { taskManagementPathName } from "../constants/common";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { isGridStatesEqual } from "../../../savings-opportunities/utils/helpers/isGridStatesEqual";
import { updateTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/updateTasksViewThunk";
import {
  setTaskManagementCurrentViewId,
  setTaskManagementSelectedTasks,
} from "../../../../../store/task-management/taskManagementSlice";
import { createTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/createTasksViewThunk";
import { deleteTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/deleteTasksViewThunk";
import { duplicateTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/duplicateTasksViewThunk";
import { protectTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/protectTasksViewThunk";
import { reorderTasksViewThunk } from "../../../../../store/task-management/thunks/views-hierarchy/actions/reorderTasksViewThunk";

export const useTasksViewActions = (gridApi: GridApi | null) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentView = useAppSelector(tasksCurrentViewSelector);

  const currentViewId = currentView?.id;
  const currentViewConfig = currentView?.config;
  const showCompleted = currentViewConfig?.showCompleted;
  const currentViewIsProtected = currentView?.protection_details?.is_protected;

  const setNewViewId = useCallback(
    (viewId: string) => {
      navigate(
        `${taskManagementPathName}/?${hierarchyViewIdSearchParamKey}=${viewId}`,
      );
      dispatch(setTaskManagementCurrentViewId(viewId));
    },
    [navigate, dispatch],
  );

  const onTabChange = useCallback(
    async (viewId: string) => {
      const smthChanged = !isGridStatesEqual({
        viewConfig: currentViewConfig?.gridConfig,
        gridState: gridApi?.getState(),
      });

      const needToUpdate =
        currentViewId && !currentViewIsProtected && smthChanged;

      if (needToUpdate) {
        await dispatch(
          updateTasksViewThunk({
            viewId: currentViewId,
            body: {
              config: {
                showCompleted,
                gridConfig: JSON.stringify(gridApi?.getState()),
              },
            },
          }),
        ).unwrap();
      }

      dispatch(setTaskManagementSelectedTasks([]));
      dispatch(setTaskManagementCurrentViewId(viewId));
    },
    [
      dispatch,
      gridApi,
      currentViewIsProtected,
      currentViewConfig,
      currentViewId,
      showCompleted,
    ],
  );

  const onViewCreate = useCallback(
    async (viewName: string) => {
      const response = await dispatch(
        createTasksViewThunk({
          body: { name: viewName },
        }),
      ).unwrap();

      if (response) {
        setNewViewId(response.id);
      }
      return response;
    },
    [dispatch, setNewViewId],
  );

  const onViewRename = useCallback(
    async (viewId: string, name: string) => {
      return await dispatch(
        updateTasksViewThunk({ viewId, body: { name: name } }),
      ).unwrap();
    },
    [dispatch],
  );

  const onViewDelete = useCallback(
    async (viewId: string) => {
      await dispatch(deleteTasksViewThunk(viewId)).unwrap();

      if (viewId === currentViewId) {
        dispatch(setTaskManagementCurrentViewId(""));
        navigate(taskManagementPathName);
      }
    },
    [dispatch, navigate, currentViewId],
  );

  const onViewDuplicate = useCallback(
    async (viewId: string, name: string) => {
      const response = await dispatch(
        duplicateTasksViewThunk({
          viewId,
          name,
        }),
      ).unwrap();

      if (response) {
        setNewViewId(response.id);
      }

      return response;
    },
    [dispatch, setNewViewId],
  );

  const onViewProtect = useCallback(
    async (viewId: string, isProtected: boolean, description: string) => {
      return await dispatch(
        protectTasksViewThunk({
          viewId,
          body: {
            is_protected: isProtected,
            protection_description: description,
          },
        }),
      ).unwrap();
    },
    [dispatch],
  );

  const onViewReorder = useCallback(
    (id: string, index: number) => {
      dispatch(
        reorderTasksViewThunk({
          id,
          index,
        }),
      );
    },
    [dispatch],
  );

  return {
    onTabChange,
    onViewCreate,
    onViewRename,
    onViewDelete,
    onViewDuplicate,
    onViewProtect,
    onViewReorder,
  };
};
