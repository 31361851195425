import {
  Integration,
  PutUsersMeByTypeIntegrationsAndIntegrationIdApiArg,
  PostUsersMeByTypeIntegrationsApiArg,
} from "../../services/cloudchipr.api";

export const generateCreatePayloadData = (
  data: Integration,
): PostUsersMeByTypeIntegrationsApiArg => ({
  type: data.type,
  body: {
    name: data.name,
    emails: data.emails,
    token: data.token,
    url: data.url,
    content_type: data.content_type,
    api_token: data.api_token,
    jira_email: data.jira_email,
    default_project_key: data.default_project_key,
    default_issue_type_id: data.default_issue_type_id,
    conversation: data?.conversation
      ? {
          id: data?.conversation?.id,
          display_name: data?.conversation?.display_name,
          name: data?.conversation?.name,
          type: data?.conversation?.type,
        }
      : undefined,
    conversations:
      data?.conversations?.map(({ issue, ...data }) => data) ?? null,
  },
});

export const generateUpdatePayloadData = (
  data: Integration,
): PutUsersMeByTypeIntegrationsAndIntegrationIdApiArg => {
  const {
    type,
    body: { api_token, token, ...body },
  } = generateCreatePayloadData(data);

  return { type, body, integrationId: data.id };
};
