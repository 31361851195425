import { FC, useCallback } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterTriggerComponentProps } from "../../../select/dropdown-select/utils/types/filterTriggerComponentProps";
import { SlackConversationLabel } from "../../../../pages/integrations/components/communications/slack/components/SlackConversationLabel";

export const SlackConversationsDropdownTrigger: FC<
  FilterTriggerComponentProps
> = ({ onCloseAndSubmit, options, error, selectedValues, onClick, open }) => {
  const deleteHandler = useCallback(
    (value: string) => () => {
      onCloseAndSubmit(selectedValues.filter((sv) => sv !== value));
    },
    [onCloseAndSubmit, selectedValues],
  );

  return (
    <Stack
      py={1}
      pr={0.5}
      pl={2}
      border={1}
      direction="row"
      onClick={onClick}
      borderRadius={1}
      justifyContent="space-between"
      borderColor={error ? "error.main" : "grey.300"}
      sx={{
        ":hover": { borderColor: "initial" },
        cursor: "pointer",
      }}
    >
      {selectedValues.length ? (
        <Stack direction="row" gap={1} flexWrap="wrap">
          {selectedValues.map((value) => {
            const option = options?.find(
              (option) => option.value === value,
            )?.rawValue;

            return (
              <Chip
                key={value}
                size="small"
                variant="outlined"
                label={
                  <SlackConversationLabel
                    type={option?.type}
                    name={option?.name ?? value}
                  />
                }
                onDelete={deleteHandler(value)}
              />
            );
          })}
        </Stack>
      ) : (
        <Typography color={error ? "error.main" : "text.secondary"}>
          Select Conversations
        </Typography>
      )}

      {!open ? (
        <ArrowDropDownIcon color="action" />
      ) : (
        <ArrowDropUpIcon color="action" />
      )}
    </Stack>
  );
};
