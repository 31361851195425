import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getSavingsOpportunitiesThunk = createAsyncThunk(
  "savingsOpportunities/getSavingsOpportunities",
  async (forceRefetch: boolean | void, { dispatch }) => {
    const { getOrganisationsCurrentSavingsOpportunities } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getOrganisationsCurrentSavingsOpportunities.initiate(undefined, {
        forceRefetch: !!forceRefetch,
      }),
    ).unwrap();
  },
);
