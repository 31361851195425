import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { TaskStatusCell } from "../../cells/TaskStatusCell";
import { NullableEmbeddedTask } from "../../../../../../../../../../../services/cloudchipr.api";

type Target = {
  resourceId: string;
  task: NullableEmbeddedTask;
};

interface ToolbarTaskCreateActionItemTooltipContentProps {
  targets?: Target[];
}

export const ToolbarTaskCreateActionItemTooltipContent: FC<
  ToolbarTaskCreateActionItemTooltipContentProps
> = ({ targets }) => {
  return (
    <Stack p={1} spacing={1}>
      <Typography
        fontSize={12}
        letterSpacing="0.14px"
        variant="caption"
        fontWeight="medium"
        color="text.secondary"
      >
        All selected resources already have assigned tasks:
      </Typography>
      <ul style={{ paddingLeft: 16 }}>
        {targets?.map(({ task, resourceId }) => {
          if (!task) {
            return;
          }

          return (
            <li key={resourceId}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TypographyWithTooltip variant="caption" title={resourceId} />
                <TaskStatusCell task={task} />
              </Stack>
            </li>
          );
        })}
      </ul>
    </Stack>
  );
};
