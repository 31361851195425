import { FC, useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { KubernetesClusterCard } from "./components/KubernetesClusterCard";
import { KubernetesCluster } from "../../../../../../../services/cloudchipr.api";
import { KubernetesClusterCardDragPreview } from "../KubernetesClusterCardDragPreview";

interface KubernetesClustersGridViewProps {
  clusters: KubernetesCluster[] | null;
  onSortingChange: (data: KubernetesCluster[], drop?: boolean) => void;
}

export const KubernetesClustersGridView: FC<
  KubernetesClustersGridViewProps
> = ({ clusters, onSortingChange }) => {
  const [data, setData] = useState(clusters);

  const cardMoveHandler = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      setData((data) => {
        if (!data) {
          return data;
        }

        const dndData = [...data];
        const firstItem = dndData.splice(draggedRowIndex, 1).at(0);
        if (firstItem) {
          dndData.splice(targetRowIndex, 0, firstItem);
        }

        return dndData;
      });
    },
    [],
  );

  const cardDropHandler = useCallback(
    (draggedRowIndex: number, targetRowIndex: number, drop: boolean) => {
      let fetchingData: KubernetesCluster[] | null = null;

      setData((data) => {
        if (!data) {
          return data;
        }

        const dndData = [...data];

        const firstItem = dndData.splice(draggedRowIndex, 1).at(0);
        if (firstItem) {
          dndData.splice(targetRowIndex, 0, firstItem);
        }

        fetchingData = dndData;
        return dndData;
      });

      fetchingData && onSortingChange(fetchingData, drop);
    },
    [onSortingChange],
  );

  useEffect(() => {
    if (!clusters?.length) {
      return;
    }

    setData(clusters);
  }, [clusters]);

  if (!clusters?.length) {
    return null;
  }

  return (
    <Grid
      container
      spacing={1}
      mt={0}
      pb={1}
      ml={-1}
      columns={{ xs: 2, lg: 3, xl: 4, xxl: 6 }}
    >
      {data?.map((cluster: KubernetesCluster, index: number) => {
        return (
          <Grid item xs={1} key={cluster.id}>
            <KubernetesClusterCard
              {...cluster}
              disableDnD={data?.length === 1}
              index={index}
              onCardMove={cardMoveHandler}
              onCardDrop={cardDropHandler}
            />
          </Grid>
        );
      })}
      <KubernetesClusterCardDragPreview clusters={clusters} />
    </Grid>
  );
};
