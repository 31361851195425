import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Editor } from "../../../../../../../storybook/editor/Editor";
import {
  NotificationCustomMessageContent,
  NotificationCustomMessageSlackMentionsConfig,
} from "../../../utils/types/types";
import { NestedIntegration } from "../../../../../../../services/cloudchipr.api";
import { SlackConversationSelect } from "../../../../../integrations/components/communications/slack/components/form/components/conversation-select/SlackConversationSelect";

export interface NotificationCustomMessageDialogContentProps
  extends NotificationCustomMessageContent {
  message: string;
  type: NestedIntegration["type"];
  onChange(message: string): void;
  mention?: NotificationCustomMessageSlackMentionsConfig;
}

export const NotificationCustomMessageDialogContent: FC<
  NotificationCustomMessageDialogContentProps
> = ({ BeforeMessage, message, AfterMessage, type, onChange, mention }) => {
  return (
    <Stack spacing={1} p={2} bgcolor="grey.50">
      {BeforeMessage && <BeforeMessage type={type} />}

      <Box flexGrow={1}>
        <Typography variant="body1" fontWeight="bold" mb={1}>
          Message
        </Typography>

        <Box bgcolor="white" border={1} borderColor="grey.300" borderRadius={2}>
          <Editor
            border={false}
            content={message}
            minHeight={200}
            onChange={onChange}
            placeholder="Write a custom message..."
            commands={["bold", "italic", "strike", "link"]}
          />

          {mention && type === "slack" && (
            <Box p={2} pt={0}>
              <SlackConversationSelect
                label="Mention People (Optional)"
                id={mention.integrationId}
                onChange={mention.onChange}
                selectedConversations={mention.selected}
              />
            </Box>
          )}
        </Box>
      </Box>

      {AfterMessage && <AfterMessage type={type} />}
    </Stack>
  );
};
