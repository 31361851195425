import { Box, LinearProgress } from "@mui/material";
import { CommunicationIntegrations } from "./components/communications/CommunicationIntegrations";
import { PageHeader } from "../common/PageHeader";
import { useGetUsersMeIntegrationsQuery } from "../../../services/cloudchipr.api";

export const Integrations = () => {
  const { isLoading: loading } = useGetUsersMeIntegrationsQuery({});

  return (
    <Box bgcolor="white" height="100%">
      <PageHeader title="Integrations" />

      {loading && <LinearProgress />}

      <CommunicationIntegrations />
    </Box>
  );
};
