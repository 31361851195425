import { FC, MouseEvent, useCallback, useMemo } from "react";
import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SxProps } from "@mui/system";
import { indigo } from "@mui/material/colors";
import { useDndContext } from "@dnd-kit/core";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { HierarchyViewActionButton } from "../actions/HierarchyViewActionButton";
import { View } from "../../../../../../services/cloudchipr.api";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";

interface ViewsHierarchyMoreTabsDropdownLabelProps {
  locked?: boolean;
  view: View;
  onClose?(): void;
  selected?: boolean;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyMoreTabsDropdownLabel: FC<
  ViewsHierarchyMoreTabsDropdownLabelProps
> = ({ onClose, selected, view, onTabChange, locked }) => {
  const { ref, hovered } = useHover();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: view.id });
  const { active } = useDndContext();

  const menuItemStyles: SxProps = useMemo(
    () => ({
      position: isDragging ? "relative" : undefined,
      zIndex: isDragging ? 1000 : 1,
      boxShadow: isDragging ? "0 0 5px rgba(0,0,0,0.2)" : 0,
      backgroundColor: selected
        ? `${indigo[50]} !important`
        : "white !important",
      transform: CSS.Transform.toString(transform),
      transition,
      pl: 0.5,
    }),
    [isDragging, transform, transition, selected],
  );

  const clickHandler = useCallback(() => {
    onClose?.();
    onTabChange(view.id);
  }, [onTabChange, onClose, view.id]);

  return (
    <MenuItem
      disableGutters
      ref={setNodeRef}
      value={view.id}
      selected={selected}
      sx={menuItemStyles}
      onClick={clickHandler}
    >
      <IconButton
        sx={{ cursor: "grab" }}
        size="small"
        {...listeners}
        {...attributes}
        disabled={locked}
        onClick={preventDefaultAndStopPropagation}
        onMouseDown={preventDefaultAndStopPropagation}
      >
        <DragIndicatorIcon
          color={locked ? "disabled" : "action"}
          fontSize="small"
        />
      </IconButton>

      <Stack
        ref={ref}
        direction="row"
        flex={1}
        spacing={1}
        pr={1}
        alignItems="center"
        justifyContent="space-between"
        sx={cropTextStyles}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={cropTextStyles}
        >
          {!!view?.protection_details?.is_protected && (
            <VerifiedUserOutlined sx={{ fontSize: 16 }} />
          )}

          {active ? (
            <Typography variant="inherit" sx={cropTextStyles}>
              {view.name}
            </Typography>
          ) : (
            <TypographyWithTooltip title={view.name} variant="inherit" />
          )}
        </Stack>
        <HierarchyViewActionButton
          view={view}
          onClose={onClose}
          visible={hovered || !!selected}
        />
      </Stack>
    </MenuItem>
  );
};

const cropTextStyles = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const preventDefaultAndStopPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
};
