import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { IntegrationType } from "../integrationsSlice";

export const getIntegrationByIdThunk = createAsyncThunk(
  "integrations/getIntegrationById",
  async ({ id, type }: { id: string; type: IntegrationType }, { dispatch }) => {
    return await dispatch(
      cloudChiprApi.endpoints.getUsersMeByTypeIntegrationsAndIntegrationId.initiate(
        { integrationId: id, type },
        { forceRefetch: true },
      ),
    );
  },
);
