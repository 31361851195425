import { FC, useMemo } from "react";
import Stack from "@mui/material/Stack";
import { Button, Tooltip } from "@mui/material";
import { filterTreeFiltersAreInvalid } from "../../../../../../../common/filters-tree/utils/helpers/filterTreeFiltersAreInvalid";
import { FilterTreeNodeWithId } from "../../../../../../../common/filters-tree/utils/types/common";

interface ResourceExplorerFilterTreeActionsProps {
  onCancel(): void;
  onApply(): void;
  filterTree?: FilterTreeNodeWithId;
}

export const ResourceExplorerFilterTreeActions: FC<
  ResourceExplorerFilterTreeActionsProps
> = ({ filterTree, onCancel, onApply }) => {
  const invalid = useMemo(() => {
    return filterTree && filterTreeFiltersAreInvalid(filterTree);
  }, [filterTree]);

  return (
    <Stack
      mt={2}
      pt={1}
      pb={2}
      direction="row"
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
      bottom={0}
      bgcolor="white"
      position="sticky"
      zIndex="appBar"
    >
      <Button color="tertiary" onClick={onCancel}>
        Cancel
      </Button>

      <Tooltip
        arrow
        placement="top"
        title={
          invalid
            ? "Please select a value for all filters to apply filters."
            : ""
        }
      >
        <span>
          <Button
            color="primary"
            variant="contained"
            onClick={onApply}
            disabled={invalid}
          >
            Apply Filters
          </Button>
        </span>
      </Tooltip>
    </Stack>
  );
};
