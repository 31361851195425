import { FC, useCallback } from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import { FloatingActionButton } from "./FloatingActionButton";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";
import { useAppAbility } from "../../../../../../services/permissions";
import { taskCurrentViewConfigShowCompletedSelector } from "../../../../../../store/task-management/selectors/taskCurrentViewConfigShowCompletedSelector";
import { setTaskManagementSelectedTasks } from "../../../../../../store/task-management/taskManagementSlice";
import { enqueueSnackbarErrorAlert } from "../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const FloatingMarkCompleteButton: FC = () => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(taskAvailableStatusPropertiesSelector);
  const showCompleted = useAppSelector(
    taskCurrentViewConfigShowCompletedSelector,
  );
  const completedId = statuses?.find(
    (status) => status.category === "completed",
  )?.id;
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const clickHandler = useCallback(async () => {
    if (!completedId) {
      return;
    }

    try {
      await dispatch(
        taskManagementUpdateSelectedTasksThunk({
          status_id: completedId,
        }),
      );

      if (!showCompleted) {
        dispatch(setTaskManagementSelectedTasks([]));
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  }, [dispatch, completedId, showCompleted]);

  return (
    <FloatingActionButton
      icon={CheckCircleOutline}
      disabled={!canEditTask}
      onClick={clickHandler}
    >
      Mark As Complete
    </FloatingActionButton>
  );
};
