import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { IntegrationLogo } from "../../../../../../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import {
  initiateCurrentIntegration,
  IntegrationType,
} from "../../../../../../../../../../../../../store/integrations/integrationsSlice";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { setLiveUsageMgmtSelectedResources } from "../../../../../../../../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { liveUsageMgmtProviderSelector } from "../../../../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

interface IntegrationMenuItemProps {
  id: string;
  type: IntegrationType;
  resourceType: ResourceType;
  disabled?: boolean;
  label: string;
}

export const IntegrationMenuItemV2: FC<IntegrationMenuItemProps> = ({
  id,
  type,
  resourceType,
  label,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const clickHandler = useCallback(() => {
    dispatch(
      setLiveUsageMgmtSelectedResources({
        resourceType,
        selectedResources: { [id]: true },
        parentSource: resourceType,
      }),
    );
    dispatch(initiateCurrentIntegration({ type, resourceType, provider }));
  }, [dispatch, type, resourceType, id, provider]);

  return (
    <MenuItem dense onClick={clickHandler} disabled={disabled}>
      <ListItemIcon>
        <IntegrationLogo type={type} width={20} />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};
