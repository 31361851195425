import { FC, useCallback } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { taskCurrentViewConfigShowCompletedSelector } from "../../../../../../store/task-management/selectors/taskCurrentViewConfigShowCompletedSelector";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";
import { updateTaskViewConfigShowCompletedThunk } from "../../../../../../store/task-management/thunks/updateTaskViewConfigShowCompletedThunk";
import { setTaskManagementSelectedTasks } from "../../../../../../store/task-management/taskManagementSlice";

export const TaskManagementShowCompleted: FC = () => {
  const dispatch = useAppDispatch();

  const show = useAppSelector(taskCurrentViewConfigShowCompletedSelector);
  const empty = useAppSelector(tasksListIsEmptySelector);

  const toggleCompletedHandler = useCallback(() => {
    dispatch(setTaskManagementSelectedTasks([]));
    dispatch(updateTaskViewConfigShowCompletedThunk());
  }, [dispatch]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      py={0.5}
      px={1}
      border={1}
      borderColor="grey.400"
      borderLeft={4}
      borderRadius={1}
      sx={borderLeftColorStyle}
    >
      <Typography fontSize={13}>Show Completed</Typography>

      <Switch
        checked={show}
        size="small"
        disabled={empty}
        onClick={toggleCompletedHandler}
      />
    </Stack>
  );
};

const borderLeftColorStyle = {
  borderLeftColor: "#009688",
};
