import { ColDef, GridApi, IMultiFilterParams } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { SavingsOpportunityActionTypeCellRenderer } from "./cell-renderers/SavingsOpportunityActionTypeCellRenderer";
import { SavingsOpportunityServiceCellRenderer } from "./cell-renderers/SavingsOpportunityServiceCellRenderer";
import { SavingsOpportunityImplementationEffortCellRenderer } from "./cell-renderers/SavingsOpportunityImplementationEffortCellRenderer";
import { SavingsOpportunityAccountCellRenderer } from "./cell-renderers/SavingsOpportunityAccountCellRenderer";
import { SavingsOpportunityTagsCellRenderer } from "./cell-renderers/SavingsOpportunityTagsCellRenderer";
import { SavingsOpportunityMonthlySavingsCellRenderer } from "./cell-renderers/SavingsOpportunityMonthlySavingsCellRenderer";
import { getSimpleTypographyCellRenderer } from "./cell-renderers/getSimpleTypographyCellRenderer";
import { getMoneyTypographyCellRenderer } from "./cell-renderers/getMoneyTypographyCellRenderer";
import { SavingsOpportunityBasedOnPastCellRenderer } from "./cell-renderers/SavingsOpportunityBasedOnPastCellRenderer";
import { SavingsOpportunityCloudProviderCellRenderer } from "./cell-renderers/SavingsOpportunityCloudProviderCellRenderer";
import { getSavingsOpportunitiesInnerHeader } from "./header/SavingsOpportunitiesInnerHeader";
import { getSavingsOpportunitiesPriceInnerHeader } from "./header/SavingsOpportunitiesPriceInnerHeader";
import {
  SavingsOpportunity,
  TaskStatus,
} from "../../../../services/cloudchipr.api";
import { agGridEmptyColumnToFitEmptySpace } from "../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";
import { TaskStatusCell } from "../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/TaskStatusCell";
import {
  getSavingsOpportunitiesStatusesValueGetter,
  savingsOpportunitiesAccountValueGetter,
  savingsOpportunitiesBasedOnPastValueGetter,
  savingsOpportunitiesCloudProviderValueGetter,
  savingsOpportunitiesImplementationEffortValueGetter,
  savingsOpportunitiesOpportunityTypeValueGetter,
  savingsOpportunitiesServiceValueGetter,
  savingsOpportunitiesTagsValueGetter,
} from "../utils/helpers/valueGetters";
import {
  getSavingsOpportunitiesTaskQuickFilterTextGetter,
  savingsOpportunitiesCloudProviderQuickFilterTextGetter,
  savingsOpportunitiesOpportunityTypeQuickFilterTextGetter,
  savingsOpportunitiesServiceQuickFilterTextGetter,
  savingsOpportunitiesTagsQuickFilterTextGetter,
} from "../utils/helpers/quickFilterTextGetters";
import {
  getSavingsOpportunitiesTaskComparator,
  savingsOpportunitiesAccountComparator,
  savingsOpportunitiesCloudProviderComparator,
  savingsOpportunitiesImplementationEffortComparator,
  savingsOpportunitiesTagsComparator,
} from "../utils/helpers/comparators";

export const getSavingsOpportunitiesTableColumns = (
  taskStatuses?: TaskStatus[],
): ColDef<SavingsOpportunity>[] => [
  {
    field: "action_type",
    headerName: "Opportunity Type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityActionTypeCellRenderer,
    valueGetter: savingsOpportunitiesOpportunityTypeValueGetter,
    getQuickFilterText:
      savingsOpportunitiesOpportunityTypeQuickFilterTextGetter,
  },

  {
    field: "description",
    maxWidth: 400,
    headerName: "Description",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: getSimpleTypographyCellRenderer("description"),
  },

  {
    field: "cloud_provider",
    headerName: "Cloud Provider",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityCloudProviderCellRenderer,
    valueGetter: savingsOpportunitiesCloudProviderValueGetter,
    getQuickFilterText: savingsOpportunitiesCloudProviderQuickFilterTextGetter,
    comparator: savingsOpportunitiesCloudProviderComparator,
  },

  {
    field: "service",
    headerName: "Service",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityServiceCellRenderer,
    valueGetter: savingsOpportunitiesServiceValueGetter,
    getQuickFilterText: savingsOpportunitiesServiceQuickFilterTextGetter,
  },

  {
    field: "resource_type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource Type",
    cellRenderer: getSimpleTypographyCellRenderer("resource_type"),
  },

  {
    field: "recommended_type",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Recommended Type",
    cellRenderer: getSimpleTypographyCellRenderer("recommended_type"),
  },

  {
    field: "resource_id",
    maxWidth: 400,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource ID",
    cellRenderer: getSimpleTypographyCellRenderer("resource_id"),
  },

  {
    field: "resource_arn",
    maxWidth: 400,
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Resource ARN",
    cellRenderer: getSimpleTypographyCellRenderer("resource_arn"),
  },

  {
    field: "implementation_effort",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Implementation Effort",
    cellRenderer: SavingsOpportunityImplementationEffortCellRenderer,
    valueGetter: savingsOpportunitiesImplementationEffortValueGetter,
    comparator: savingsOpportunitiesImplementationEffortComparator,
  },

  {
    field: "based_on_past",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Based On Past",
    cellRenderer: SavingsOpportunityBasedOnPastCellRenderer,
    valueGetter: savingsOpportunitiesBasedOnPastValueGetter,
  },

  {
    field: "region",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    headerName: "Region",
    cellRenderer: getSimpleTypographyCellRenderer("region"),
  },

  {
    field: "account.provider_account_id",
    headerName: "Account",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesInnerHeader(),
    },
    cellRenderer: SavingsOpportunityAccountCellRenderer,
    valueGetter: savingsOpportunitiesAccountValueGetter,
    comparator: savingsOpportunitiesAccountComparator,
  },

  {
    field: "tags",
    filterParams: tagsFilterParams,
    headerName: "Tags",
    enableRowGroup: false,
    cellRenderer: SavingsOpportunityTagsCellRenderer,
    valueGetter: savingsOpportunitiesTagsValueGetter,
    getQuickFilterText: savingsOpportunitiesTagsQuickFilterTextGetter,
    comparator: savingsOpportunitiesTagsComparator,
  },

  {
    field: "current_monthly_price",
    filter: "agNumberColumnFilter",
    headerName: "Current Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesPriceInnerHeader(
        "current_monthly_price",
      ),
    },
    cellRenderer: getMoneyTypographyCellRenderer("current_monthly_price"),
  },

  {
    field: "estimated_monthly_price",
    minWidth: 300,
    filter: "agNumberColumnFilter",
    headerName: "Estimated Monthly Price",
    headerComponentParams: {
      innerHeaderComponent: getSavingsOpportunitiesPriceInnerHeader(
        "estimated_monthly_price",
        "Estimated monthly price after implementing the recommendation.",
      ),
    },
    cellRenderer: getMoneyTypographyCellRenderer("estimated_monthly_price"),
  },

  {
    field: "task",
    headerName: "Task Status",
    cellRenderer: ({ data }: CustomCellRendererProps<SavingsOpportunity>) => {
      if (!data?.task) {
        return "-";
      }

      return <TaskStatusCell task={data.task} />;
    },
    valueGetter: getSavingsOpportunitiesStatusesValueGetter(taskStatuses),
    getQuickFilterText:
      getSavingsOpportunitiesTaskQuickFilterTextGetter(taskStatuses),
    comparator: getSavingsOpportunitiesTaskComparator(taskStatuses),
  },

  {
    field: "monthly_savings",
    filter: "agNumberColumnFilter",
    suppressColumnsToolPanel: true,
    pinned: "right",
    lockPinned: true,
    headerName: "Monthly Savings",
    headerComponentParams: {
      innerHeaderComponent:
        getSavingsOpportunitiesPriceInnerHeader("monthly_savings"),
    },
    cellRenderer: SavingsOpportunityMonthlySavingsCellRenderer,
  },

  agGridEmptyColumnToFitEmptySpace,
];

const tagsFilterParams = {
  filters: [
    { filter: "agTextColumnFilter" },
    {
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params: any) => {
          const gridApi = params.api as GridApi<SavingsOpportunity>;

          const uniqueValues = new Set<string>();

          gridApi?.forEachNode((node) => {
            node?.data?.tags?.forEach((tag) => {
              uniqueValues.add(`${tag.key}: ${tag.value}`);
            });
          });

          params?.success(Array.from(uniqueValues));
        },
      },
    },
  ],
} as IMultiFilterParams;
