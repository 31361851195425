import { FC, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { useSortable } from "@dnd-kit/sortable";
import { SxProps } from "@mui/system";
import { CSS } from "@dnd-kit/utilities";
import { useDndContext } from "@dnd-kit/core";
import { HierarchyViewActionButton } from "./actions/HierarchyViewActionButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { viewTabMaxWidth } from "../utils/constants/constants";
import { View } from "../../../../../services/cloudchipr.api";

interface ViewsHierarchyTabProps {
  view: View;
  selected: boolean;
  value: string;
  overlayDragging?: boolean;
  onTabChange?(viewId: string): void;
}

export const ViewsHierarchyTab: FC<ViewsHierarchyTabProps> = ({
  view,
  value,
  selected,
  onTabChange,
  overlayDragging,
}) => {
  const { ref: hoverRef, hovered } = useHover();
  const actionVisible = selected ?? hovered;

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({
      id: view.id,
    });
  const { active } = useDndContext();

  const styles: SxProps = useMemo(() => {
    let zIndex: number | undefined;
    let borderColor: string | undefined = "grey.300";

    if (selected) {
      borderColor = "primary.main";
    }

    if (isDragging) {
      zIndex = 100;
      borderColor = undefined;
    }

    if (overlayDragging) {
      zIndex = 1000;
      borderColor = undefined;
    }

    return {
      position: overlayDragging || isDragging ? "relative" : undefined,
      zIndex,
      opacity: isDragging ? 0.5 : 1,
      transform: overlayDragging ? "none" : CSS.Transform.toString(transform),
      borderBottom: 2,
      borderColor: selected ? "primary.main" : "transparent",
      ":hover": {
        borderColor,
      },
      ...tabStyles,
    };
  }, [isDragging, transform, selected, overlayDragging]);

  const draggableButtonStyles = useMemo(
    () => ({
      ...buttonStyles,
      opacity: isDragging ? 0.75 : 1,
      backgroundColor: isDragging ? "grey.200" : undefined,
    }),
    [isDragging],
  );

  const protectionStartIcon = useMemo(() => {
    if (!view.protection_details?.is_protected) {
      return null;
    }

    return (
      <Tooltip
        arrow
        placement="top"
        title="View is protected, all changes will not be autosaved"
      >
        <VerifiedUserOutlined
          sx={{ fontSize: 18 }}
          color={selected ? "primary" : "action"}
        />
      </Tooltip>
    );
  }, [selected, view.protection_details?.is_protected]);

  const typographyProps: TypographyProps = useMemo(
    () => ({
      variant: "body2",
      fontWeight: "medium",
      sx: { textTransform: "none" },
      color: selected ? "primary" : "text.primary",
    }),
    [selected],
  );

  const clickHandler = useCallback(() => {
    onTabChange?.(value);
  }, [value, onTabChange]);

  return (
    <Box ref={setNodeRef} {...attributes} {...listeners} sx={styles}>
      <Button
        ref={hoverRef}
        onClick={clickHandler}
        variant="text"
        size="xsmall"
        color="tertiary"
        startIcon={protectionStartIcon}
        sx={draggableButtonStyles}
        endIcon={
          <HierarchyViewActionButton view={view} visible={actionVisible} />
        }
      >
        {active ? (
          <Typography {...typographyProps} sx={typographyTruncateStyles}>
            {view.name}
          </Typography>
        ) : (
          <TypographyWithTooltip
            placement="top"
            title={view.name}
            {...typographyProps}
          />
        )}
      </Button>
    </Box>
  );
};

const typographyTruncateStyles = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const buttonStyles = {
  backgroundColor: "white",
  maxWidth: viewTabMaxWidth - 10,
  borderRadius: 1.5,
  "& .MuiButton-endIcon": {
    marginLeft: 0.25,
  },
  "& .MuiButton-startIcon": {
    marginRight: 0.5,
  },

  ":hover": {
    backgroundColor: (theme: Theme) => theme.palette.action.hover,
  },
};

const tabStyles = {
  pb: 1.25,
  pt: 1.5,
  mr: 0.5,
  maxWidth: viewTabMaxWidth,
  minWidth: 60,
  cursor: "pointer",
};
