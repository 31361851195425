import { FC, Fragment, useCallback } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AccountCardLabel } from "./AccountCardLabel";
import { LastUpdateDate } from "./LastUpdateDate";
import { money } from "../../../../../../utils/numeral/money";
import { PieChart } from "../../../../../../storybook/charts/pie-chart/PieChart";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
interface AccountCardContentProps {
  ems: number;
  spending: number;
  failed: boolean;
  failedTooltip?: string;
  changeAccountInfoDrawerState?: (state: boolean) => void;
  last_resource_updated_date: NullableDate;
}

export const AccountCardContent: FC<AccountCardContentProps> = ({
  ems,
  spending,
  failed,
  failedTooltip,
  changeAccountInfoDrawerState,
  last_resource_updated_date,
}) => {
  const isEmpty = !spending && !ems;

  let cardContent = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={5}
    >
      <PieChart
        stroke
        partial={ems}
        total={spending}
        empty={isEmpty}
        error={failed}
      />
      {isEmpty ? (
        <Typography
          variant="body2"
          fontWeight="medium"
          textAlign="center"
          color="text.secondary"
        >
          No Resources Found
        </Typography>
      ) : (
        <Stack spacing={2}>
          <AccountCardLabel
            label="Filtered resources"
            value={failed ? "----" : money(ems)}
            color="error"
          />
          <AccountCardLabel
            label="Tracked total"
            value={failed ? "----" : money(spending)}
            color="primary"
          />
        </Stack>
      )}
    </Stack>
  );

  if (failed && failedTooltip) {
    cardContent = (
      <Tooltip title={failedTooltip} followCursor>
        {cardContent}
      </Tooltip>
    );
  }

  const onHelpIconClick = useCallback(
    (event: any) => {
      if (changeAccountInfoDrawerState) {
        event.preventDefault();
        changeAccountInfoDrawerState(true);
      }
    },
    [changeAccountInfoDrawerState],
  );

  return (
    <Fragment>
      <Stack direction="row" justifyContent="space-between" pb={2}>
        <Stack direction="column">
          <Typography fontWeight="bold" color="text.primary" fontSize={14}>
            Live tracked resources
          </Typography>
          <LastUpdateDate
            date={last_resource_updated_date}
            sx={{
              color: "text.secondary",
            }}
          />
        </Stack>
        <Box
          sx={{
            px: 0.5,
            cursor: "pointer",
          }}
          onClick={onHelpIconClick}
        >
          <HelpOutlineIcon fontSize="small" color="info" />
        </Box>
      </Stack>
      {cardContent}
    </Fragment>
  );
};
