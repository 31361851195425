import { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import { Editor } from "../../../../../storybook/editor/Editor";
import { NotificationCustomMessageSlackMentionsConfig } from "../../../../pages/common/notification-selection/utils/types/types";
import { SlackConversationSelect } from "../../../../pages/integrations/components/communications/slack/components/form/components/conversation-select/SlackConversationSelect";

interface CreateIntegrationMessageEditorProps {
  value: string;
  onChange(value: string): void;
  mention?: NotificationCustomMessageSlackMentionsConfig;
}

export const CreateIntegrationMessageEditor: FC<CreateIntegrationMessageEditorProps> =
  memo(({ value, onChange, mention }) => {
    return (
      <Box flexGrow={1}>
        <Typography variant="body1" fontWeight="bold" mb={1}>
          Message
        </Typography>

        <Box bgcolor="white" border={1} borderColor="grey.300" borderRadius={2}>
          <Editor
            border={false}
            content={value}
            minHeight={120}
            placeholder="Write a message..."
            onChange={onChange}
            commands={[["bold", "italic", "strike"], "link"]}
          />

          {mention && (
            <Box p={1} pt={0}>
              <SlackConversationSelect
                label="Mention People (Optional)"
                id={mention.integrationId}
                onChange={mention.onChange}
                selectedConversations={mention.selected}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  });
