import { FC, useCallback, useMemo } from "react";
import { IconButton } from "@mui/material";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { getExistingTasksFromOpportunities } from "./utils/helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { taskManagementInitiateEmptyTaskThunk } from "../../../../../../store/task-management/thunks/form/taskManagementInitiateEmptyTaskThunk";
import { setTaskManagementTaskTarget } from "../../../../../../store/task-management/taskManagementSlice";
import { savingsOpportunitiesSelectedItemsSelector } from "../../../../../../store/savings-opportunities/selectors/selection/savingsOpportunitiesSelectedItemsSelector";
import { OpportunityTarget } from "../../../../../../services/cloudchipr.api";
import { ToolbarTaskCreateActionItemTooltipContent } from "../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/toolbar/actions/ToolbarTaskCreateActionItemTooltipContent";
import { TasksTooltipWithContent } from "../../../../task-management/components/TasksTooltipWithContent";

export const SavingsOpportunitiesCreateTaskToolbarAction: FC = () => {
  const dispatch = useAppDispatch();
  const opportunities = useAppSelector(
    savingsOpportunitiesSelectedItemsSelector,
  );
  const allHadTask = useMemo(() => {
    return (
      opportunities?.filter((opportunity) => !!opportunity.task).length ===
      opportunities?.length
    );
  }, [opportunities]);
  const targets = useMemo(
    () =>
      opportunities?.map((opportunity) => ({
        resourceId: opportunity.resource_id ?? "",
        task: opportunity.task,
      })),
    [opportunities],
  );

  const clickHandler = useCallback(async () => {
    const firstOpportunity =
      opportunities?.length === 1 ? opportunities?.at(0) : undefined;

    await dispatch(
      taskManagementInitiateEmptyTaskThunk(
        firstOpportunity
          ? { description: firstOpportunity.description }
          : undefined,
      ),
    );

    dispatch(
      setTaskManagementTaskTarget({
        type: "opportunity",
        existingTasks: getExistingTasksFromOpportunities(opportunities),
        opportunities:
          opportunities?.map((opportunity) => {
            return {
              ...opportunity,
              state: null,
              cloud_provider: opportunity.account.provider,
              provider_identifier: opportunity.resource_id,
              current_type: opportunity.resource_type,
              resource_type: opportunity.service,
              price_per_month: opportunity.current_monthly_price,
              live_resource_link_response: null,
            } as OpportunityTarget;
          }) ?? [],
      }),
    );
  }, [dispatch, opportunities]);

  const disabled = !opportunities?.length;
  const tooltipText = `${disabled ? "Select rows to " : ""}Create Task`;

  return (
    <TasksTooltipWithContent
      title={
        allHadTask ? (
          <ToolbarTaskCreateActionItemTooltipContent targets={targets} />
        ) : (
          tooltipText
        )
      }
    >
      <span>
        <IconButton
          size="small"
          onClick={clickHandler}
          disabled={disabled || allHadTask}
        >
          <PostAddOutlinedIcon
            color={disabled || allHadTask ? "disabled" : "primary"}
            fontSize="small"
          />
        </IconButton>
      </span>
    </TasksTooltipWithContent>
  );
};
