import {
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
} from "@mui/material";
import { FC, useCallback } from "react";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { CommunicationIntegrationCreation } from "./CommunicationIntegrationCreation";
import { integrationsDrawerOpenSelector } from "../../../../../../../../store/integrations/selectors/integrationsDrawerOpenSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import {
  IntegrationType,
  setActiveIntegration,
} from "../../../../../../../../store/integrations/integrationsSlice";
import { integrationDetails } from "../../utils/constants/integrationDetails";
import { useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery } from "../../../../../../../../services/cloudchipr.api";
import { activeIntegrationTypeSelector } from "../../../../../../../../store/integrations/selectors/activeIntegrationTypeSelector";
import { activeIntegrationIdSelector } from "../../../../../../../../store/integrations/selectors/activeIntegrationIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CommunicationIntegrationDrawerProps {
  onNewIntegrationCreate?(type: IntegrationType, integrationId: string): void;
  onCancel?(): void;
}

export const CommunicationIntegrationDrawer: FC<
  CommunicationIntegrationDrawerProps
> = ({ onNewIntegrationCreate, onCancel }) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(integrationsDrawerOpenSelector);
  const id = useAppSelector(activeIntegrationIdSelector);
  const type = useAppSelector(activeIntegrationTypeSelector);

  const closeHandler = useCallback(() => {
    onCancel?.();
    dispatch(setActiveIntegration({ type: null, id: null }));
  }, [dispatch, onCancel]);

  const drawerHeading = getDrawerHeading(id, type);

  const { data, isLoading, isFetching, error } =
    useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery(
      { type: type || "", integrationId: id || "" },
      { skip: !id || !type, refetchOnMountOrArgChange: true },
    );

  if (error && "status" in error && error.status === 400) {
    // @ts-expect-error todo: fix api spec;
    enqueueSnackbarErrorAlert(error?.data?.message);
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeHandler}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <Stack
        py={1.25}
        px={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" fontWeight="medium">
          {drawerHeading}
        </Typography>
        <IconButton size="small" onClick={closeHandler}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />

      {isLoading || isFetching ? (
        <Stack alignItems="center" justifyContent="center" minHeight={200}>
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <CommunicationIntegrationCreation
          integration={id ? data : undefined}
          type={type ?? undefined}
          onCancel={closeHandler}
          onSubmit={closeHandler}
          onNewIntegrationCreate={onNewIntegrationCreate}
        />
      )}
    </Drawer>
  );
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};

const paperProps = {
  sx: {
    width: "50dvw",
    minWidth: 670,
    maxWidth: 1000,
  },
};

const getDrawerHeading = (
  integrationId: string | null,
  integrationType: IntegrationType | null,
) => {
  if (integrationId) {
    return "Edit";
  }

  if (integrationType) {
    return integrationDetails[integrationType].label;
  }

  return "";
};
