import {
  Point,
  TooltipOptions,
  TooltipPositionerCallbackFunction,
} from "highcharts";
import {
  getHighchartsTooltipHTML,
  TooltipValueFormatterType,
} from "./getHighchartsTooltipHTML";

export const getTooltipOptions = ({
  valueFormatter,
  showTotal,
}: {
  valueFormatter?: TooltipValueFormatterType;
  showTotal: boolean;
}): TooltipOptions => {
  return {
    shared: false,
    useHTML: true,
    borderRadius: 12,
    backgroundColor: "white",
    borderColor: "#ddd",
    shadow: false,
    borderWidth: 1,
    stickOnContact: true,
    followPointer: true,
    positioner: tooltipPositioner,
    formatter: function () {
      // @ts-expect-error | the type of "this" defined as "Point" but it's wrong
      const point = this.point as Point;
      const pointIndex = point?.index ?? 0;

      const total = showTotal
        ? this.series.chart.series.reduce((total, series) => {
            if (
              series.visible &&
              series.data[pointIndex] &&
              series.data[pointIndex].y !== null
            ) {
              total += series.data?.[pointIndex]?.y ?? 0;
            }

            return total;
          }, 0)
        : null;

      return getHighchartsTooltipHTML({
        point,
        valueFormatter,
        title: this.category,
        total: showTotal ? total : null,
      });
    },
  };
};

const tooltipPositioner: TooltipPositionerCallbackFunction = function (
  labelWidth,
  labelHeight,
  point,
) {
  const chart = this.chart;
  const plotLeft = chart.plotLeft;
  const plotWidth = chart.plotWidth;
  const plotTop = chart.plotTop;
  const plotHeight = chart.plotHeight;
  const rightEdge = plotLeft + plotWidth;
  const bottomEdge = plotTop + plotHeight;

  // Compute tentative x position (to the right of the point)
  let xPos = point.plotX + plotLeft + 20;
  // Compute tentative y position (above the point)
  let yPos = point.plotY + plotTop - labelHeight - 10;

  // Horizontal adjustments:
  if (xPos + labelWidth > rightEdge) {
    // Not enough room on right, position to the left of the point
    xPos = point.plotX + plotLeft - labelWidth - 20;
  }
  if (xPos < plotLeft) {
    // Ensure we don't go off the left edge
    xPos = plotLeft;
  }

  // Vertical adjustments:
  if (yPos < plotTop) {
    // If the tooltip goes above the chart, try placing it below the point.
    yPos = point.plotY + plotTop + 10;
    // If that still makes it go off the bottom, clamp it.
    if (yPos + labelHeight > bottomEdge) {
      yPos = bottomEdge - labelHeight;
    }
  } else if (yPos + labelHeight > bottomEdge) {
    // If the tooltip goes off the bottom, try placing it above the point.
    yPos = point.plotY + plotTop - labelHeight - 10;
    // If that pushes it off the top, then clamp it.
    if (yPos < plotTop) {
      yPos = plotTop;
    }
  }

  return { x: xPos, y: yPos };
};
