import { FC } from "react";
import { Button, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { taskManagementTargetOpportunitiesSelector } from "../../../../../../../../../../../store/task-management/selectors/form/target/opportunities/taskManagementTargetOpportunitiesSelector";
import { opportunityIdSearchParam } from "../../../../../../../../../savings-opportunities/utils/constants/common";
import { savingsOpportunitiesExistSelector } from "../../../../../../../../../../../store/savings-opportunities/selectors/savingsOpportunitiesExistSelector";

export const ViewTargetInOpportunitiesButton: FC = () => {
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);
  const opportunities = useAppSelector(
    taskManagementTargetOpportunitiesSelector,
  );
  const opportunityId = opportunities?.at(0)?.id;
  const opportunitiesExist = useAppSelector((state) =>
    savingsOpportunitiesExistSelector(state, opportunityId ?? ""),
  );

  if (!editMode) {
    return null;
  }

  return (
    <Tooltip
      arrow
      placement="top"
      title={!opportunitiesExist ? "Opportunity not found" : ""}
    >
      <span>
        <Button
          sx={sx}
          size="small"
          target="_blank"
          variant="outlined"
          component={RouterLink}
          disabled={!opportunitiesExist}
          to={`/recommendations/savings-opportunities?${opportunityIdSearchParam}=${opportunityId}`}
          startIcon={
            <SelectAllIcon
              fontSize="small"
              color={opportunitiesExist ? "primary" : "action"}
            />
          }
        >
          View In Opportunities
        </Button>
      </span>
    </Tooltip>
  );
};

const sx = { textTransform: "none" };
