import { IntegrationType } from "../../../../../../../../../../../store/integrations/integrationsSlice";

type IntegrationSelectButtonOption = {
  type: IntegrationType;
  label: string;
};
export const integrationOptions: IntegrationSelectButtonOption[] = [
  {
    type: "jira",
    label: "Create Jira",
  },
  {
    type: "slack",
    label: "Send Slack",
  },
  {
    type: "email",
    label: "Send Email",
  },
];

export const cleanActionDisabledReasonText: Map<
  "non_selected_data" | "multi_resource_type_selection",
  string
> = new Map([
  ["non_selected_data", "Select at least 1 resource to enable actions."],
  [
    "multi_resource_type_selection",
    "To perform actions, please select resources of the same type.",
  ],
]);

export const oneDayInSeconds = 86400;
