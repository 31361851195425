import { ColDef } from "ag-grid-community";
import {
  SavingsOpportunity,
  TaskStatus,
} from "../../../../../services/cloudchipr.api";
import { opportunityTypeTitlesByActionType } from "../constants/common";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";

export const savingsOpportunitiesOpportunityTypeQuickFilterTextGetter: ColDef<SavingsOpportunity>["getQuickFilterText"] =
  ({ data }) =>
    `${
      data?.action_type
        ? opportunityTypeTitlesByActionType[data.action_type]
        : ""
    } ${data.id}`;

export const savingsOpportunitiesCloudProviderQuickFilterTextGetter: ColDef<SavingsOpportunity>["getQuickFilterText"] =
  ({ data }) => data.account.provider;

export const savingsOpportunitiesServiceQuickFilterTextGetter: ColDef<SavingsOpportunity>["getQuickFilterText"] =
  ({ data }) => {
    return getResourceTypeName(data.service, {
      type: "long",
      singular: true,
    });
  };

export const savingsOpportunitiesTagsQuickFilterTextGetter: ColDef<SavingsOpportunity>["getQuickFilterText"] =
  ({ data }) =>
    data.tags?.map((tag) => `${tag.key} ${tag.value}`).join(" ") ?? "";

export const getSavingsOpportunitiesTaskQuickFilterTextGetter =
  (
    taskStatuses?: TaskStatus[],
  ): ColDef<SavingsOpportunity>["getQuickFilterText"] =>
  ({ data }) => {
    const status = taskStatuses?.find(
      (status) => status.id === data?.task?.status_id,
    );
    return `${status?.name ?? ""} ${status?.category ?? ""}`;
  };
