import { FC, Fragment, useCallback } from "react";
import { Menu, PopoverOrigin } from "@mui/material";
import { FloatingPropertyActionItem } from "./FloatingPropertyActionItem";
import {
  FloatingActionButton,
  FloatingActionButtonProps,
} from "../FloatingActionButton";
import { TaskProperties } from "../../../../utils/types/types";
import {
  taskPropertyTitle,
  taskUpdatePropertyName,
} from "../../../../utils/constants/taskPropertyTitle";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { TaskPropertySelectionEditItem } from "../../../../../common/task-management/components/common/properties/task-property-selection/TaskPropertySelectionEditItem";
import { taskManagementUpdateSelectedTasksThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementUpdateSelectedTasksThunk";
import { useAppAbility } from "../../../../../../../services/permissions";

export interface FloatingPropertyActionButtonProps
  extends FloatingActionButtonProps {
  optionsKey: TaskProperties;
}

export const FloatingPropertyActionButton: FC<
  FloatingPropertyActionButtonProps
> = ({ optionsKey, ...props }) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const availableProperties = useAppSelector(taskAvailablePropertiesSelector);
  const options = availableProperties?.[optionsKey];
  const required = optionsKey === "statuses";
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const itemClickHandler = useCallback(
    async (id: string | null) => {
      const propName = taskUpdatePropertyName.get(optionsKey);

      if (!propName) {
        return;
      }

      closeMenu();

      dispatch(
        taskManagementUpdateSelectedTasksThunk({
          [propName]:
            optionsKey === "statuses"
              ? id
              : {
                  value: id,
                },
        }),
      );
    },
    [dispatch, optionsKey, closeMenu],
  );

  return (
    <Fragment>
      <FloatingActionButton
        onClick={openMenu}
        disabled={!canEditTask}
        {...props}
      >
        {taskPropertyTitle.get(optionsKey)}
      </FloatingActionButton>

      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={slotProps}
        MenuListProps={menuListProps}
      >
        {!required && <FloatingPropertyActionItem onClick={itemClickHandler} />}

        {options?.map((option) => (
          <FloatingPropertyActionItem
            key={option.id}
            id={option.id}
            name={option.name}
            color={option.color}
            onClick={itemClickHandler}
          />
        ))}

        <TaskPropertySelectionEditItem propertyType={optionsKey} />
      </Menu>
    </Fragment>
  );
};

const slotProps = {
  paper: { sx: { mt: -2 } },
};

const menuListProps = { sx: { pb: 0 } };

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
