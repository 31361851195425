import { ValueGetterFunc } from "ag-grid-community";
import {
  SavingsOpportunity,
  TaskStatus,
} from "../../../../../services/cloudchipr.api";
import {
  opportunityTypeTitlesByActionType,
  possibleEffortsLabels,
} from "../constants/common";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";

export const savingsOpportunitiesOpportunityTypeValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) =>
  data?.action_type ? opportunityTypeTitlesByActionType[data.action_type] : "";

export const savingsOpportunitiesCloudProviderValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) =>
  data?.account?.provider
    ? getProviderName(data.account.provider, { title: true })
    : "";

export const savingsOpportunitiesServiceValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) => {
  return data?.service
    ? getResourceTypeName(data?.service, {
        type: "long",
        singular: true,
      })
    : "";
};

export const savingsOpportunitiesImplementationEffortValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) =>
  data?.implementation_effort
    ? possibleEffortsLabels[data?.implementation_effort]
    : "";

export const savingsOpportunitiesBasedOnPastValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) => (data?.based_on_past ? `${data?.based_on_past} days` : "");

export const savingsOpportunitiesAccountValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) => data?.account?.provider_account_name;

export const savingsOpportunitiesTagsValueGetter: ValueGetterFunc<
  SavingsOpportunity
> = ({ data }) =>
  data?.tags
    ?.map((tag) => `${tag.key} ${tag.value}`)
    ?.join(" ")
    ?.toLowerCase() ?? "";

export const getSavingsOpportunitiesStatusesValueGetter =
  (taskStatuses?: TaskStatus[]): ValueGetterFunc<SavingsOpportunity> =>
  ({ data }) => {
    const status = taskStatuses?.find(
      (status) => status.id === data?.task?.status_id,
    );
    return status?.name ?? "";
  };
