import { getUserPermissionInfoText } from "../../../../../../../../../../../utils/helpers/getUserPermissionInfoText";
import { liveUsageRowActionTooltipTitles } from "../constants/constants";
import { NullableEmbeddedTask } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

export const getToolbarTaskCreateActionItemTooltipText = ({
  disabled,
  cannotCreateTask,
}: {
  disabled?: boolean;
  cannotCreateTask?: boolean;
}) => {
  if (cannotCreateTask) {
    return getUserPermissionInfoText("Create Task");
  }

  return liveUsageRowActionTooltipTitles.crate_task[
    disabled ? "disabled" : "active"
  ];
};

export const getExistingTasksFromResources = (
  resources: ResourceDataType[],
): Record<string, NullableEmbeddedTask> =>
  resources
    .filter((resource) => !!resource.resource.task)
    .reduce(
      (acc, resource) => {
        acc[resource.resource.provider_unique_identifier ?? resource.id] =
          resource.resource.task;

        return acc;
      },
      {} as Record<string, NullableEmbeddedTask>,
    );
