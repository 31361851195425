import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerChartDataSelectorV2 } from "./resourceExplorerChartDataSelectorV2";
import { HighchartsChartDataType } from "../../../../../storybook/c8r-highcharts/common/utils/types/common";

export const resourceExplorerChartDataSeriesMapSelector =
  createDraftSafeSelector(
    [resourceExplorerChartDataSelectorV2],
    (chartData) => {
      return chartData?.series?.reduce(
        (result, item) => {
          result.set(item.name, {
            data: item.data,
            color: item.color,
          });
          return result;
        },
        new Map() as Map<
          string,
          Omit<HighchartsChartDataType["series"][0], "name">
        >,
      );
    },
  );
