import { FC, useCallback, useMemo } from "react";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { IconButton } from "@mui/material";
import { ToolbarTaskCreateActionItemTooltipContent } from "./ToolbarTaskCreateActionItemTooltipContent";
import {
  getExistingTasksFromResources,
  getToolbarTaskCreateActionItemTooltipText,
} from "./utils/helpers/helpers";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { taskManagementInitiateEmptyTaskThunk } from "../../../../../../../../../../../store/task-management/thunks/form/taskManagementInitiateEmptyTaskThunk";
import { setTaskManagementTaskTarget } from "../../../../../../../../../../../store/task-management/taskManagementSlice";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { TasksTooltipWithContent } from "../../../../../../../../../task-management/components/TasksTooltipWithContent";

interface ToolbarTaskCreateActionItemProps {
  resourceType: ResourceType;
  disabled: boolean;
}

export const ToolbarTaskCreateActionItem: FC<
  ToolbarTaskCreateActionItemProps
> = ({ resourceType, disabled }) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const cannotCreateTask = cannot("create", "task");

  const resources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );
  const targets = useMemo(
    () =>
      resources?.map((resource) => ({
        resourceId: resource.resource.provider_unique_identifier ?? resource.id,
        task: resource.resource.task,
      })),
    [resources],
  );
  const allHadTask = useMemo(() => {
    return (
      resources?.filter((resource) => !!resource.resource.task).length ===
      resources?.length
    );
  }, [resources]);
  const buttonDisabled = disabled || cannotCreateTask || allHadTask;

  const clickHandler = useCallback(async () => {
    if (!resources?.length) {
      return;
    }

    await dispatch(taskManagementInitiateEmptyTaskThunk());
    dispatch(
      setTaskManagementTaskTarget({
        type: "resource",
        existingTasks: getExistingTasksFromResources(resources),
        resources: resources.map((resource) => {
          return {
            state: "live",
            id: resource.id,
            name: resource.resource.name ?? "",
            tags: resource.resource.tags,
            link: resource.resource.link,
            account: resource.resource.account,
            resource_type: resource.resource_type,
            region: resource.resource.region ?? "",
            price_per_month: `${resource.resource.price_per_month}`,
            provider_identifier: resource.resource.provider_unique_identifier,
            creation_data: resource.resource.creation_data ?? {},
            live_resource_link_response: null,
          };
        }),
      }),
    );
  }, [dispatch, resources]);

  const tooltip = getToolbarTaskCreateActionItemTooltipText({
    disabled,
    cannotCreateTask,
  });

  return (
    <TasksTooltipWithContent
      title={
        allHadTask ? (
          <ToolbarTaskCreateActionItemTooltipContent targets={targets} />
        ) : (
          (tooltip ?? "")
        )
      }
    >
      <span>
        <IconButton
          sx={{ p: 0.5 }}
          onClick={clickHandler}
          disabled={buttonDisabled}
        >
          <PostAddOutlinedIcon
            color={buttonDisabled ? "disabled" : "primary"}
          />
        </IconButton>
      </span>
    </TasksTooltipWithContent>
  );
};
