import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerWidgetDataSelector } from "./resource-explorer-widget/resourceExplorerWidgetDataSelector";

export const resourceExplorerChartDataSeriesTotalCostsMapSelector =
  createDraftSafeSelector([resourceExplorerWidgetDataSelector], (chartData) => {
    if (!chartData?.data) {
      return;
    }
    return chartData.data.reduce(
      (result, item, index) => {
        const key = index.toString();

        item?.items?.forEach((child) => {
          const total = result.get(key) ?? 0;
          if (child.field) {
            const itemCost = child?.cost ?? 0;
            const newValue = +(total + itemCost).toFixed(2);

            result.set(key, newValue);
          }
        });

        return result;
      },
      new Map() as Map<string, number>,
    );
  });
