import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";
import * as Sentry from "@sentry/react";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { ViewAccessCheck } from "./routes/ViewAccessCheck";
import { ExecutionLogs } from "../pages/execution-log/ExecutionLogs";
import { OffHoursV1 } from "../pages/schedule/off-hours/OffHoursV1";
import { OffHoursItem } from "../pages/schedule/off-hours/OffHoursItem";
import { OffHoursRecommendations } from "../pages/recommendations/components/off-hours-recommendations/OffHoursRecommendations";
import { CloudChat } from "../pages/cloudchat/CloudChat";
import { RedirectPage } from "../pages/redirect/RedirectPage";
import { RightsizingRecommendations } from "../pages/recommendations/components/rightsizing-recommendations/RightsizingRecommendations";
import { ResourceExplorer } from "../pages/resource-explorer/ResourceExplorer";
import { NotFound } from "../error-pages/NotFound";
import { AccessDenied } from "../error-pages/AccessDenied";
import { useAppSelector } from "../../store/hooks";
import { ScheduleResourceProtection } from "../pages/schedule/resource-protection/ScheduleResourceProtection";
import { AccountsOldUrlsTmpGuard } from "../pages/accounts-group/old-urls-guards/AccountsOldUrlsTmpGuard";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { currentOrganizationSelector } from "../../store/organizations/organizationsSelectors";
import { MainLayout } from "../layout/MainLayout";
import { SmartTagPreferences } from "../pages/settings/components/smart-tag/SmartTagPreferences";
import { Users } from "../pages/settings/components/users/Users";
import { Billing } from "../pages/settings/components/billing/Billing";
import { Organization } from "../pages/settings/components/organization/Organization";
import { ApiKeysList } from "../pages/settings/components/api-keys/ApiKeysList";
import { Profile } from "../pages/settings/components/profile/Profile";
import { AuthAndSSO } from "../pages/settings/components/auth/AuthAndSSO";
import { AccountSetup } from "../pages/account-setup/AccountSetup";
import { UtilizationAndCoveragePage } from "../pages/commtiments/utilization-and-coverage/UtilizationAndCoveragePage";
import { EmailNotifications } from "../pages/settings/components/email-notifications/EmailNotifications";
import { SingleRightsizingRecommendation } from "../pages/recommendations/components/single-rightsizing-recommendation/SingleRightsizingRecommendation";
import { Dimensions } from "../pages/dimensions/Dimensions";
import { OffHours } from "../pages/automations/off-hours/data-grid/OffHours";
import { SingleOffHours } from "../pages/automations/off-hours/single-off-hours/SingleOffHours";
import { SingleWorkflow } from "../pages/automations/workflows/single-workflow/SingleWorkflow";
import { WorkflowsPage } from "../pages/automations/workflows/data-grid/WorkflowsPage";
import { BudgetsAndAlerts } from "../pages/budgets-and-alerts/BudgetsAndAlerts";
import { Commitments } from "../pages/commtiments/Commitments";
import { ResourceExplorerAllViews } from "../pages/resource-explorer-all-views/ResourceExplorerAllViews";
import { DashboardAllItems } from "../pages/dashboard-all-items/DashboardAllItems";
import { SavingsOpportunities } from "../pages/savings-opportunities/SavingsOpportunities";
import { Reports } from "../pages/reports/Reports";
import { TaskManagement } from "../pages/task-management/TaskManagement";
import { UserRoles } from "../pages/settings/components/roles/UserRoles";
import { UserRoleEdit } from "../pages/settings/components/roles/edit/UserRoleEdit";
import { currentUserIdSelector } from "../../store/profile/currentUserIdSelector";
import { AccountV1 } from "../pages/accounts-group/old-urls-guards/AccountV1";
import { AccountV2 } from "../pages/accounts-group/old-urls-guards/AccountV2";
import { SlackIntegration } from "../pages/integrations/components/communications/slack/SlackIntegration";
import { EmailIntegration } from "../pages/integrations/components/communications/email/EmailIntegration";
import { WebhookIntegration } from "../pages/integrations/components/communications/webhook/WebhookIntegration";
import { JiraIntegration } from "../pages/integrations/components/communications/jira/JiraIntegration";
import { Integrations } from "../pages/integrations/Integrations";
import { AccountsProviderCheckWrapper } from "../pages/accounts-group/components/AccountsProviderCheckWrapper";
import { LiveUsageMgmtProviderCheckWrapper } from "../pages/live-usage-mgmt/LiveUsageMgmtProviderCheckWrapper";
import { CloudAccountHeaderWrapper } from "../pages/accounts-group/components/header/CloudAccountHeaderWrapper";
import { IntegrationsSlackAppConnection } from "../pages/integrations/components/communications/slack/components/IntegrationsSlackAppConnection";

const SentryRoutes = Sentry.withSentryRouting(Routes);

export const AppRouter: FC = () => {
  const userId = useAppSelector(currentUserIdSelector);
  const organization = useAppSelector(currentOrganizationSelector);

  const updateContext = useUnleashContext();
  const enableReservedInstances = useFlag("EnableReservedInstances");

  useEffect(() => {
    updateContext({
      userId,
      properties: { organizationId: organization?.id ?? "" },
    });
  }, [userId, updateContext, organization?.id]);

  if (!userId) {
    return null;
  }

  const redirectUrl = "/settings/access-denied";

  return (
    <SentryRoutes>
      <Route
        path="/account/setup"
        element={
          <ProtectedRoute>
            <ViewAccessCheck subject="account">
              <AccountSetup />
            </ViewAccessCheck>
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<RedirectPage />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="/access-denied" element={<AccessDenied />} />

        <Route
          path="/providers"
          element={
            <ViewAccessCheck subject="account">
              <CloudAccountHeaderWrapper />
            </ViewAccessCheck>
          }
        >
          <Route
            path=":provider/accounts/:orgId"
            element={<AccountsProviderCheckWrapper />}
          />
          <Route
            path=":provider/live-resources"
            element={<LiveUsageMgmtProviderCheckWrapper />}
          />
        </Route>

        {/*/!*old urls ===============================================================================================*!/*/}
        <Route path="/aws/orgs/:orgId" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/aws" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/gcp" element={<AccountsOldUrlsTmpGuard />} />
        <Route path="/azure" element={<AccountsOldUrlsTmpGuard />} />

        <Route
          path="/aws/:orgId/accounts"
          element={<AccountsOldUrlsTmpGuard />}
        />
        <Route
          path="/gcp/:orgId/accounts"
          element={<AccountsOldUrlsTmpGuard />}
        />
        <Route
          path="/azure/:orgId/accounts"
          element={<AccountsOldUrlsTmpGuard />}
        />

        <Route path="/aws/live-usage" element={<AccountV2 provider="aws" />} />
        <Route path="/gcp/live-usage" element={<AccountV2 provider="gcp" />} />
        <Route
          path="/azure/live-usage"
          element={<AccountV2 provider="azure" />}
        />

        <Route
          path="/aws/:accountId/*"
          element={<AccountV1 provider="aws" />}
        />
        <Route
          path="/gcp/:accountId/*"
          element={<AccountV1 provider="gcp" />}
        />
        <Route
          path="/azure/:accountId/*"
          element={<AccountV1 provider="azure" />}
        />
        {/*/!*old urls ==========================================================================================*!/*/}

        <Route
          path="/dashboards"
          element={<ViewAccessCheck subject="dashboard" />}
        >
          <Route index element={<DashboardAllItems />} />

          <Route path=":dashboardId" element={<Dashboard />} />
        </Route>
        <Route
          path="/resource-explorer"
          element={<ViewAccessCheck subject="resource-explorer" />}
        >
          <Route index element={<ResourceExplorerAllViews />} />
          <Route path=":viewId" element={<ResourceExplorer />} />
        </Route>

        <Route path="/cloudchat" element={<CloudChat />} />

        <Route
          path="/schedule"
          element={<ViewAccessCheck subject="schedule" />}
        >
          <Route path="off-hours" element={<OffHoursV1 />} />
          <Route path="off-hours/create" element={<OffHoursItem />} />
          <Route path="off-hours/edit/:id" element={<OffHoursItem />} />
        </Route>

        <Route
          path="/automations"
          element={<ViewAccessCheck subject="schedule" />}
        >
          <Route path="workflow" element={<WorkflowsPage />} />
          <Route path="workflow/create" element={<SingleWorkflow />} />
          <Route path="workflow/edit/:id" element={<SingleWorkflow />} />

          <Route path="off-hours" element={<OffHours />} />
          <Route path="off-hours/create" element={<SingleOffHours />} />
          <Route path="off-hours/edit/:id" element={<SingleOffHours />} />
        </Route>

        <Route
          path="schedule-resource-protection/:scheduleId/:batchId"
          element={<ScheduleResourceProtection />}
        />

        <Route
          path="/recommendations"
          element={<ViewAccessCheck subject="recommendation" />}
        >
          <Route path="off-hours" element={<OffHoursRecommendations />} />
          <Route path="off-hours/:type" element={<OffHoursRecommendations />} />
          <Route path="rightsizing" element={<RightsizingRecommendations />} />
          <Route
            path="rightsizing/:recommendationId"
            element={<SingleRightsizingRecommendation />}
          />

          <Route
            path="savings-opportunities"
            element={
              <ViewAccessCheck subject="category" redirectUrl={redirectUrl}>
                <SavingsOpportunities />
              </ViewAccessCheck>
            }
          />
        </Route>

        <Route
          path="/task-mgmt"
          element={
            <ViewAccessCheck subject="task" redirectUrl={redirectUrl}>
              <TaskManagement />
            </ViewAccessCheck>
          }
        >
          <Route path=":viewId" element={<TaskManagement />} />
        </Route>

        <Route
          path="/dimensions"
          element={
            <ViewAccessCheck subject="category" redirectUrl={redirectUrl}>
              <Dimensions />
            </ViewAccessCheck>
          }
        />

        <Route
          path="/integrations"
          element={<ViewAccessCheck subject="integration" />}
        >
          <Route index element={<Integrations />} />
          <Route path="slack" element={<SlackIntegration />} />
          <Route path="email" element={<EmailIntegration />} />
          <Route path="webhook" element={<WebhookIntegration />} />
          <Route path="jira" element={<JiraIntegration />} />

          <Route
            path="slack-app-connection"
            element={<IntegrationsSlackAppConnection />}
          />
          {/* Redirects for old integration URLs */}
          <Route
            path="create"
            element={<Navigate to="/integrations" replace />}
          />
          <Route
            path="edit/*"
            element={<Navigate to="/integrations" replace />}
          />
        </Route>

        {/* Redirect for old budgets URLs */}
        <Route path="/budgets/*" element={<Navigate to="/alerts" replace />} />

        <Route path="/alerts" element={<ViewAccessCheck subject="alert" />}>
          <Route path="" element={<BudgetsAndAlerts />} />
        </Route>

        <Route
          path="/subscriptions"
          element={<ViewAccessCheck subject="report" />}
        >
          <Route path="" element={<Reports />} />
        </Route>

        <Route
          path="/execution-log"
          element={<ViewAccessCheck subject="account" />}
        >
          <Route path="" element={<ExecutionLogs />} />
          <Route path=":id" element={<ExecutionLogs />} />
        </Route>

        {enableReservedInstances ? (
          <Route
            path="/commitments"
            element={<ViewAccessCheck subject="commitment" />}
          >
            <Route path=":provider/:orgId" element={<Commitments />} />
            <Route path=":orgId" element={<Commitments />} />
            <Route path="" element={<Commitments />} />
          </Route>
        ) : (
          <Route
            path="/commitments"
            element={<ViewAccessCheck subject="commitment" />}
          >
            <Route
              path="utilization-and-coverage/:orgId"
              element={<UtilizationAndCoveragePage />}
            />
            <Route
              path="utilization-and-coverage"
              element={<UtilizationAndCoveragePage />}
            />
          </Route>
        )}

        <Route path="/settings">
          <Route path="access-denied" element={<AccessDenied />} />

          <Route
            path="smart-tag"
            element={
              <ViewAccessCheck subject="account" redirectUrl={redirectUrl}>
                <SmartTagPreferences />
              </ViewAccessCheck>
            }
          />
          <Route
            path="users"
            element={
              <ViewAccessCheck subject="user" redirectUrl={redirectUrl}>
                <Users />
              </ViewAccessCheck>
            }
          />
          <Route
            path="roles"
            element={
              <ViewAccessCheck subject="user" redirectUrl={redirectUrl}>
                <UserRoles />
              </ViewAccessCheck>
            }
          />
          <Route
            path="roles/edit/:id"
            element={
              <ViewAccessCheck subject="user" redirectUrl={redirectUrl}>
                <UserRoleEdit />
              </ViewAccessCheck>
            }
          />
          <Route
            path="billing"
            element={
              <ViewAccessCheck subject="billing" redirectUrl={redirectUrl}>
                <Billing />
              </ViewAccessCheck>
            }
          />
          <Route
            path="organization"
            element={
              <ViewAccessCheck subject="organisation" redirectUrl={redirectUrl}>
                <Organization />
              </ViewAccessCheck>
            }
          />
          <Route
            path="api-keys"
            element={
              <ViewAccessCheck subject="api-key" redirectUrl={redirectUrl}>
                <ApiKeysList />
              </ViewAccessCheck>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route path="notifications" element={<EmailNotifications />} />
          <Route path="auth" element={<AuthAndSSO />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};
