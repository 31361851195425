import { generateKubernetesNodeDetailsChartData } from "./generateKubernetesNodeDetailsChartData";
import { generateKubernetesResourceDetailsHeaderChipsData } from "./generateKubernetesResourceDetailsHeaderChipsData";
import { KubernetesNode } from "../../../../../../../services/cloudchipr.api";
import { ResourceDetailsTargetType } from "../../../../../../resource-details/utils/types/common";
import { KubernetesResourceDataType } from "../../../../../utils/types/types";
import { KubernetesSpecificMetadata } from "../../../../../../resource-details/utils/types/k8sSpecific";

export const generateKubernetesResourceDetailsTarget = (
  resource: KubernetesResourceDataType,
): ResourceDetailsTargetType => {
  const chartData =
    resource.resource?.resource_type === "kubernetes_node"
      ? generateKubernetesNodeDetailsChartData(
          resource.resource as KubernetesNode,
        )
      : [];

  const providerMetadata: KubernetesSpecificMetadata = {
    labels: resource.resource.labels,
    usage: { chartData },
    headerChips: generateKubernetesResourceDetailsHeaderChipsData(resource),
  };

  const target: ResourceDetailsTargetType = {
    region: "",
    provider_unique_identifier: resource.resource?.id,
    billing_explorer_resource_identifier: resource.resource?.id,
    name: resource.resource?.name ?? "-",
    resourceType: resource.resource.resource_type,
    provider: resource.resource.cluster?.provider,
    providerAccountId: resource.resource.cluster?.provider_id,
    providerAccountName: resource.resource.cluster?.name,
    accountId: resource.resource.cluster?.id,
    providerMetadata: { k8s: providerMetadata },
  };

  if ("region" in resource.resource) {
    target.region = resource.resource.region;
  }

  return target;
};
