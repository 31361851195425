import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { ResourceTarget } from "../../../../../../services/cloudchipr.api";
import { isTaskManagementDrawerEditModeSelector } from "../../options/isTaskManagementDrawerEditModeSelector";
import { TaskTargetResource } from "../../../../utils/types/types";
import { taskManagementTargetExistingTasksTargetIdsSelector } from "../common/taskManagementTargetExistingTasksTargetIdsSelector";

export const taskManagementTargetResourcesSelector = (
  state: RootState,
): ResourceTarget[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetResource;
  const resourceIdsInTasks =
    taskManagementTargetExistingTasksTargetIdsSelector(state);
  const editMode = isTaskManagementDrawerEditModeSelector(state);

  if (editMode) {
    return target?.resources;
  }

  return target?.resources?.filter(
    (resource) =>
      !resourceIdsInTasks?.includes(
        resource.provider_identifier ?? resource.id,
      ),
  );
};
