import { FC, useCallback, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useEffectOnceWhen } from "rooks";
import { IntegrationsRow } from "./components/rows/IntegrationsRow";
import { ProjectsRow } from "./components/rows/ProjectsRow";
import { SummaryRow } from "./components/rows/SummaryRow";
import { DescriptionRow } from "./components/rows/DescriptionRow";
import { CreateJiraTicketFormActions } from "./components/CreateJiraTicketFormActions";
import { JiraSnackbarLink } from "./components/JiraSnackbarLink";
import { useJiraValidationHook } from "./utils/hooks/useJiraValidation.hook";
import { htmlToJiraADF } from "./utils/helpers/htmlToJiraADF";
import { IssueTypeRowV2 } from "./components/rows/IssueTypeRowV2";
import { UserDefinedCustomRowsV2 } from "./components/rows/custom/UserDefinedCustomRowsV2";
import {
  PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiArg,
  ResourceType,
  useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery,
  usePostUsersMeJiraIntegrationsByIntegrationIdIssuesMutation,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { getLiveUsageMgmtResourceTypeDataThunk } from "../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourceTypeDataThunk";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedRowsIdsByResourceTypeSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedRowsIdsByResourceTypeSelector";
import { jiraDescriptionSelectorV2 } from "../../../../../store/integrations/selectors/jira/jiraDescriptionSelectorV2";
import { liveUsageMgmtSelectedResourcesAccountsMetaDataSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountsMetaDataSelector";
import { enqueueSnackbarErrorAlert } from "../../../../pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CreateJiraTicketDialogProps {
  resourceType: ResourceType;
  onClose(): void;
}

const validationSchema = Yup.object({
  integrationId: Yup.string().required("This field is required"),
  body: Yup.object({
    project_key: Yup.string().required("This field is required"),
    issue_type_id: Yup.string().required("This field is required"),
    summary: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
  }),
});

const initialValues: PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiArg = {
  integrationId: "",
  body: {
    project_key: "",
    issue_type_id: "",
    summary: "",
    description: "",
    resource_ids: [],
    fields: {},
  },
};

export const CreateJiraTicketDialog: FC<CreateJiraTicketDialogProps> = ({
  resourceType,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedRowsIdsByResourceTypeSelector(state, resourceType),
  );
  const accountData = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesAccountsMetaDataSelector(state, resourceType),
  );
  const selectedResourcesIds = Object.keys(selectedResources || {});

  const initialDescription = useAppSelector((state) =>
    jiraDescriptionSelectorV2(state, resourceType),
  );

  const [creteTicket, { isLoading }] =
    usePostUsersMeJiraIntegrationsByIntegrationIdIssuesMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError }) => {
      const errors = await validateCustomFields(values.body.fields);

      if (errors) {
        errors.forEach((err: Record<string, string>) => {
          const [key, value] = Object.entries(err)[0];
          setFieldError(`body.fields.${key}`, value);
        });

        return;
      }

      try {
        const description = htmlToJiraADF(
          values.body.description || "",
          true,
        ) as string;

        const payload = {
          ...values,
          body: { ...values.body, description },
        };

        const response = await creteTicket(payload).unwrap();

        enqueueSnackbar("Jira ticket successfully created.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
          action: <JiraSnackbarLink link={response.link} />,
        });

        dispatch(getLiveUsageMgmtResourceTypeDataThunk(resourceType));

        onClose();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const {
    isValid,
    values,
    submitForm,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = formik;

  const validateCustomFields = useJiraValidationHook();

  const { data: selectedIntegration } =
    useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery(
      { type: "jira", integrationId: values.integrationId },
      { skip: !values.integrationId, refetchOnMountOrArgChange: true },
    );

  const projectChangeHandler = useCallback(
    (projectKey: string) => {
      setFieldValue("body.project_key", projectKey);

      let issueTypeId = selectedIntegration?.default_issue_type_id || "";
      if (projectKey !== selectedIntegration?.default_project_key) {
        issueTypeId = "";
      }

      setFieldValue("body.issue_type_id", issueTypeId);
      setFieldValue("body.fields", {});
    },
    [setFieldValue, selectedIntegration],
  );

  const integrationIdSelectHandler = useCallback(
    (integrationId: string, projectKey?: string, issueTypeId?: string) => {
      setFieldValue("integrationId", integrationId);

      projectKey && setFieldValue("body.project_key", projectKey);
      issueTypeId && setFieldValue("body.issue_type_id", issueTypeId);
      setFieldValue("body.fields", {});
    },
    [setFieldValue],
  );

  const accountNames = useMemo(() => {
    return accountData?.reduce((result, item) => {
      result = result + `${!result.length ? "" : ", "}` + item.name;

      return result;
    }, "");
  }, [accountData]);

  useEffectOnceWhen(
    () => {
      const providerType = provider
        ? getProviderName(provider, { title: true })
        : "";

      setFieldValue(
        "body.summary",
        `Cloudchipr - ${providerType} ${accountNames}`,
      );
      setFieldValue("body.description", initialDescription);
      setFieldValue("body.resource_ids", selectedResourcesIds);
    },
    !!(
      provider &&
      accountNames &&
      selectedResourcesIds?.length &&
      initialDescription &&
      accountData?.length
    ),
  );

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitleClosable title="Create a Jira ticket" onClose={onClose} />

      <DialogContent dividers>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <IntegrationsRow
            integrationId={values.integrationId}
            onChange={integrationIdSelectHandler}
          />
          <ProjectsRow
            onChange={projectChangeHandler}
            integrationId={values.integrationId}
            projectKey={values.body.project_key}
            error={touched.body?.project_key ? errors.body?.project_key : ""}
          />
          <IssueTypeRowV2
            projectKey={values.body.project_key}
            error={
              touched.body?.issue_type_id ? errors.body?.issue_type_id : ""
            }
            integrationId={values.integrationId}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            selectedIssueId={values.body.issue_type_id}
          />
          <UserDefinedCustomRowsV2
            errors={errors}
            projectKey={values.body.project_key}
            issueType={values.body.issue_type_id}
            onChange={handleChange}
            key={values.integrationId}
            setFieldValue={setFieldValue}
            integrationId={values.integrationId}
            values={
              values.body as PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiArg["body"]
            }
          />
          <SummaryRow summary={values.body.summary} onChange={handleChange} />

          <DescriptionRow
            setFieldValue={setFieldValue}
            description={values.body.description || ""}
          />
        </Grid>
      </DialogContent>

      <CreateJiraTicketFormActions
        disabled={!isValid}
        onCancel={onClose}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Dialog>
  );
};
