import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { SavingsOpportunitiesCreateTaskToolbarAction } from "./actions/SavingsOpportunitiesCreateTaskToolbarAction";
import { SavingsOpportunitiesTableCsvDownloader } from "./SavingsOpportunitiesTableCSVDownloader";
import { AGGridColumnsToolPanel } from "../../../common/ag-grid/components/AGGridColumnsToolPanel";
import { AgGridAllFiltersChip } from "../../../common/ag-grid/components/filters/AgGridAllFiltersChip";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { opportunityIdSearchParam } from "../../utils/constants/common";

interface SavingsOpportunitiesTableToolbarProps {
  gridApi: GridApi | null;
}

export const SavingsOpportunitiesTableToolbar: FC<
  SavingsOpportunitiesTableToolbarProps
> = ({ gridApi }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunityId = searchParams.get(opportunityIdSearchParam);

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  useEffectOnceWhen(() => {
    if (!opportunityId) {
      return;
    }

    searchChangeHandler(opportunityId);
    searchParams.delete(opportunityIdSearchParam);
    setSearchParams(searchParams);
  }, !!opportunityId && !!gridApi);

  return (
    <Stack
      p={2}
      pt={1}
      top={0}
      spacing={2}
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      direction="row"
      justifyContent="space-between"
      borderColor="grey.300"
    >
      <span>
        <AgGridAllFiltersChip gridApi={gridApi} />
      </span>

      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          borderRadius={2}
          bgcolor="grey.100"
          spacing={0.5}
        >
          <SavingsOpportunitiesCreateTaskToolbarAction />
        </Stack>

        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
        />

        <SavingsOpportunitiesTableCsvDownloader />

        <AGGridColumnsToolPanel gridApi={gridApi} />
      </Stack>
    </Stack>
  );
};
