import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { arrayMove } from "@dnd-kit/sortable";
import { AddViewInHierarchyButtonProps } from "./adding-view/AddViewInHierarchyButton";
import { HierarchyViewRenameActionGlobalProps } from "./actions/rename/HierarchyViewRenameActionDialog";
import { HierarchyViewProtectActionGlobalProps } from "./actions/protect/HierarchyViewProtectActionDialog";
import { HierarchyViewDuplicateActionGlobalProps } from "./actions/duplicate/HierarchyViewDuplicateActionDialog";
import { HierarchyViewDeleteActionGlobalProps } from "./actions/delete/HierarchyViewDeleteActionDialog";
import { View } from "../../../../../services/cloudchipr.api";

type ViewsHierarchyContextType = Omit<ViewsHierarchyProviderProps, "children">;

const ViewsHierarchyContext = createContext({} as ViewsHierarchyContextType);

export interface ViewsHierarchyProviderProps {
  views: View[];
  pathname: string;
  allItemsLabel: string;

  actions: {
    viewCreate: AddViewInHierarchyButtonProps;
    viewDelete: HierarchyViewDeleteActionGlobalProps;
    viewDuplicate: HierarchyViewDuplicateActionGlobalProps;
    viewProtect: HierarchyViewProtectActionGlobalProps;
    viewRename: HierarchyViewRenameActionGlobalProps;
    viewReorder: {
      onReorder: (viewId: string, newIndex: number, oldIndex?: number) => void;
    };
  };

  children?: ReactNode;
}

export const ViewsHierarchyProvider: FC<ViewsHierarchyProviderProps> = ({
  children,
  views,
  actions,
  ...props
}) => {
  const [hierarchyViews, setHierarchyViews] = useState(views);
  const onViewReorder = actions.viewReorder.onReorder;

  const reorderRow = useCallback(
    (viewId: string, newIndex: number, oldIndex?: number) => {
      if (oldIndex === undefined || newIndex === oldIndex) {
        return;
      }

      if (newIndex === oldIndex) {
        return;
      }

      const newData = arrayMove(hierarchyViews, oldIndex, newIndex);
      setHierarchyViews(newData);

      onViewReorder(viewId, newIndex);
    },
    [hierarchyViews, onViewReorder],
  );

  const contextValue = useMemo(
    () => ({
      ...props,
      views: hierarchyViews,
      actions: { ...actions, viewReorder: { onReorder: reorderRow } },
    }),
    [props, hierarchyViews, actions, reorderRow],
  );

  useEffect(() => {
    setHierarchyViews(views);
  }, [views]);

  return (
    <ViewsHierarchyContext.Provider value={contextValue}>
      {children}
    </ViewsHierarchyContext.Provider>
  );
};

export const useViewsHierarchyContext = () => {
  return useContext(ViewsHierarchyContext);
};
