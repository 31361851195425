import { FC, MouseEvent as ReactMouseEvent, useCallback } from "react";
import { Box, IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HierarchyViewRenameAction } from "./rename/HierarchyViewRenameAction";
import { HierarchyViewDuplicateAction } from "./duplicate/HierarchyViewDuplicateAction";
import { HierarchyViewDeleteAction } from "./delete/HierarchyViewDeleteAction";
import { HierarchyViewProtectAction } from "./protect/HierarchyViewProtectAction";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { CopyURLAction } from "../../../../../common/copy-url/CopyURLAction";
import { hierarchyViewIdSearchParamKey } from "../../utils/constants/constants";
import { View } from "../../../../../../services/cloudchipr.api";
import { useViewsHierarchyContext } from "../ViewsHierarchyProvider";

interface HierarchyViewActionButtonProps {
  view: View;
  visible: boolean;
  onClose?(): void;
}

export const HierarchyViewActionButton: FC<HierarchyViewActionButtonProps> = ({
  view,
  visible,
  onClose,
}) => {
  const viewId = view.id;
  const isProtected = !!view?.protection_details?.is_protected;
  const viewByDefault = !viewId;
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { pathname } = useViewsHierarchyContext();

  const closeHandler = useCallback(() => {
    closeMenu();
    onClose?.();
  }, [onClose, closeMenu]);

  return (
    <Box onClick={stopPropagation} onMouseDown={stopPropagation}>
      <IconButton
        component="div"
        size="small"
        onClick={openMenu}
        sx={{ visibility: visible || open ? "visible" : "hidden", p: 0.25 }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchor}
        open={open}
        onClose={closeHandler}
        disableRestoreFocus
      >
        {!viewByDefault && (
          <HierarchyViewRenameAction
            viewId={viewId}
            viewName={view.name}
            isProtected={isProtected}
            onClose={closeHandler}
          />
        )}

        <CopyURLAction
          onClose={closeHandler}
          link={`${window.location.origin}${pathname}?${hierarchyViewIdSearchParamKey}=${viewId}`}
          title="Copy Link"
        />

        <HierarchyViewDuplicateAction
          viewName={view.name}
          viewId={viewId}
          onClose={closeHandler}
        />

        {!viewByDefault && (
          <HierarchyViewProtectAction
            viewId={viewId}
            isProtected={isProtected}
            onClose={closeHandler}
            protectedBy={
              view.protected_by?.name ?? view.protected_by?.email ?? ""
            }
            protectionDescription={view.protection_details?.description ?? ""}
          />
        )}

        {!viewByDefault && (
          <HierarchyViewDeleteAction
            viewId={viewId}
            viewName={view.name}
            isProtected={isProtected}
            onClose={closeHandler}
          />
        )}
      </Menu>
    </Box>
  );
};

const stopPropagation = (event: ReactMouseEvent) => {
  event.stopPropagation();
};
