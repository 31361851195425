import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { initiateResourceDetailsDrawerThunk } from "../../../resource-details/thunks/initiateResourceDetailsDrawerThunk";
import { liveUsageMgmtResourceTypeDataResourceByIdSelector } from "../../selectors/resource-type-data/liveUsageMgmtResourceTypeDataResourceByIdSelector";
import { liveUsageMgmtGenerateExportedResourceDataByResourceId } from "../../selectors/resource-type-data/liveUsageMgmtGenerateExportedResourceDataByResourceId";
import { generateMetricsFrequenciesFromContextData } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateMetricsFrequenciesFromContextData";
import { generateAdditionalDetailsForResourceDrawer } from "../../utils/helpers/generateAdditionalDetailsForResourceDrawer";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";

export const setLiveUsageMgmtResourceDetailsDrawerResourceDataThunk =
  createAsyncThunk(
    "liveUsageMgmt/setLiveUsageMgmtResourceDetailsDrawerResourceData",
    async (
      {
        resourceType,
        resourceId,
      }: { resourceType: ResourceType; resourceId: string },
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;

      const resource = liveUsageMgmtResourceTypeDataResourceByIdSelector(
        state,
        resourceType,
        resourceId,
      );

      if (!resource) {
        return;
      }

      const csvData =
        liveUsageMgmtGenerateExportedResourceDataByResourceId(
          state,
          resourceType,
          resourceId,
          [
            "creation_data",
            "tags",
            "cpu_value",
            "inline_metrics",
            "provider_unique_identifier",
          ],
          generateMetricsFrequenciesFromContextData({}),
        )?.at(0) ?? {};

      const details = generateAdditionalDetailsForResourceDrawer(csvData);

      details.push({
        title: "Service",
        value: getResourceTypeName(resource.resource.resource_type, {
          singular: true,
          type: "long",
          service: true,
        }),
      });

      dispatch(
        initiateResourceDetailsDrawerThunk({
          openedFrom: "liveUsage",
          availableTabs: ["general", "costs", "metrics"],
          resource: {
            region: resource.resource.region ?? "",
            provider_unique_identifier:
              resource.resource.provider_unique_identifier,
            billing_explorer_resource_identifier:
              resource.resource.billing_explorer_resource_identifier ||
              resource.resource.provider_unique_identifier,
            name:
              resource.resource.name ||
              resource.resource.provider_unique_identifier,
            tags: resource.resource.tags ?? [],
            resourceType: resource.resource.resource_type,
            provider: resource.resource.account?.provider,
            link: resource.resource.link,
            providerAccountId: resource.resource.account?.provider_account_id,
            providerAccountName:
              resource.resource.account?.provider_account_name,
            accountId: resource.resource.account?.id,
          },
          resourceAdditionalDetails: details,
        }),
      );
    },
  );
