import { FC, useCallback } from "react";
import { Box, Button, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AutoFixHigh,
  LocationOnOutlined,
  OutlinedFlag,
  WorkspacesOutlined,
} from "@mui/icons-material";
import { FloatingPropertyActionButton } from "./components/property-action/FloatingPropertyActionButton";
import { FloatingDueDateSelect } from "./components/FloatingDueDateSelect";
import { FloatingAssigneeSelect } from "./components/FloatingAssigneeSelect";
import { FloatingMarkCompleteButton } from "./components/FloatingMarkCompleteButton";
import { FloatingCopyLinksButton } from "./components/FloatingCopyLinksButton";
import { FloatingDeleteAction } from "./components/FloatingDeleteAction";
import {
  floatingToolbarDark,
  floatingToolbarDarkActive,
  floatingToolbarDarkHover,
  floatingToolbarOutlined,
} from "./utils/constants/colors";
import { FloatingStatusActionButton } from "./components/FloatingStatusActionButton";
import { useAppSelector } from "../../../../../store/hooks";
import { selectedTasksLengthSelector } from "../../../../../store/task-management/selectors/selection/selectedTasksLengthSelector";
import { useTaskManagementContext } from "../TaskManagementProvider";

export const FloatingToolbarActions: FC = () => {
  const { gridApi } = useTaskManagementContext();
  const tasksCount = useAppSelector(selectedTasksLengthSelector);

  const maxWidth = document.querySelector(".ag-root")?.clientWidth;

  const selectionRemoveHandler = useCallback(() => {
    gridApi?.deselectAll();
  }, [gridApi]);

  return (
    <Stack
      p={1}
      spacing={1}
      direction="row"
      bgcolor={floatingToolbarDark}
      borderRadius={2}
      justifyContent="space-between"
      boxShadow="0px 3px 14px 2px rgba(0, 0, 0, 0.12)"
      alignItems="center"
      maxWidth={maxWidth}
    >
      <Button
        variant="outlined"
        size="small"
        sx={unselectButtonStyles}
        color="tertiary"
        onClick={selectionRemoveHandler}
        endIcon={<ClearIcon fontSize="small" />}
      >
        {tasksCount} task{tasksCount > 1 ? "s" : ""} selected
      </Button>

      <Box pr={0.25} />
      <Box
        borderLeft={1}
        height={26}
        borderColor={floatingToolbarOutlined}
        pl={1}
      />

      <FloatingStatusActionButton />

      <FloatingPropertyActionButton icon={AutoFixHigh} optionsKey="actions" />

      <FloatingPropertyActionButton
        icon={OutlinedFlag}
        optionsKey="priorities"
      />

      <FloatingAssigneeSelect />

      <FloatingDueDateSelect />

      <FloatingPropertyActionButton
        icon={LocationOnOutlined}
        optionsKey="environments"
      />

      <FloatingPropertyActionButton
        icon={WorkspacesOutlined}
        optionsKey="owners"
      />

      <FloatingMarkCompleteButton />

      <FloatingCopyLinksButton />

      <Box pr={0.25} />
      <Box
        borderLeft={1}
        minHeight={26}
        borderColor={floatingToolbarOutlined}
        pl={1}
      />

      <FloatingDeleteAction />
    </Stack>
  );
};

const unselectButtonStyles = {
  height: 28,
  minWidth: 150,
  whiteSpace: "nowrap",
  textTransform: "none",
  color: "white",
  borderColor: floatingToolbarOutlined,
  "&:hover": {
    backgroundColor: floatingToolbarDarkHover,
  },
  "&:active": {
    backgroundColor: floatingToolbarDarkActive,
  },
  "& .MuiButton-endIcon": { marginLeft: 0.75 },
};
