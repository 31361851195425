import { Point } from "highcharts";
import { grey } from "@mui/material/colors";
import { money } from "../../../../../utils/numeral/money";

export type TooltipValueFormatterType = (point: Point) => {
  value: number | string;
  markup: string;
  footer?: string;
};

export const getHighchartsTooltipHTML = ({
  title,
  point,
  valueFormatter,
  total,
}: {
  title: string | number;
  point: Point;
  total: number | null;
  valueFormatter?: TooltipValueFormatterType;
}) => {
  let tooltipText = ``;

  // Add title at the top
  tooltipText += `<div style="font-size: 13px; color: #666; margin-bottom: 8px;">${title}</div>`;

  // Generate list of services

  let valueMarkup = `<b>${point.y}</b>`;
  const result = valueFormatter?.(point);
  const footer = result?.footer;

  if (result) {
    valueMarkup = result?.markup;
  }

  tooltipText += `<div style="display: flex; flex-direction: column; gap: 8px;">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span style="display: flex; align-items: center; font-size: 11px; margin-right: 16px;">
                            <span style="font-weight: 500; font-size: 13px; width:${point.series.name.length * 10}px; max-width: 250px; min-width: 35px; word-break: break-word; white-space: normal; padding-left: 8px; border-left: 3px solid ${point.color}">
                                ${point.series.name}
                            </span>
                        </span>
    
                      ${valueMarkup}
                    </div>
                  </div>
  `;

  if (total !== null) {
    tooltipText += `
            <div style="
                margin-top: 10px;
                padding-top: 6px;
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                font-size: 13px;
                color: ${grey[600]};
            ">
                <span>Total Cost</span>
                <b>${money(total)}</b>
            </div>
        `;
  }
  if (footer) {
    tooltipText += footer;
  }

  return tooltipText;
};
