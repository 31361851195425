import { ColDef } from "ag-grid-community";
import {
  SavingsOpportunity,
  TaskStatus,
} from "../../../../../services/cloudchipr.api";
import { possibleEfforts } from "../constants/common";

export const savingsOpportunitiesCloudProviderComparator: ColDef<SavingsOpportunity>["comparator"] =
  (valueA, valueB, nodeA, nodeB) => {
    const current = nodeA?.data?.account?.provider;
    const next = nodeB?.data?.account?.provider;

    if (!current) return 1;
    if (!next) return -1;

    return current.localeCompare(next);
  };

export const savingsOpportunitiesImplementationEffortComparator: ColDef<SavingsOpportunity>["comparator"] =
  (valueA, valueB, nodeA, nodeB) => {
    const current = nodeA?.data?.implementation_effort;
    const next = nodeB?.data?.implementation_effort;

    const currentEffortIndex = possibleEfforts.findIndex(
      (effort) => effort === current,
    );

    const nextEffortIndex = possibleEfforts.findIndex(
      (effort) => effort === next,
    );

    return currentEffortIndex - nextEffortIndex;
  };

export const savingsOpportunitiesAccountComparator: ColDef<SavingsOpportunity>["comparator"] =
  (valueA, valueB, nodeA, nodeB) => {
    const current = nodeA?.data?.account?.provider_account_name;
    const next = nodeB?.data?.account?.provider_account_name;

    if (!current) return 1;
    if (!next) return -1;

    return current.localeCompare(next);
  };

export const savingsOpportunitiesTagsComparator: ColDef<SavingsOpportunity>["comparator"] =
  (valueA, valueB, nodeA, nodeB) => {
    const current = nodeA?.data?.tags?.length ?? 0;
    const next = nodeB?.data?.tags?.length ?? 0;

    return current - next;
  };

export const getSavingsOpportunitiesTaskComparator =
  (taskStatuses?: TaskStatus[]): ColDef<SavingsOpportunity>["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.task?.status_id ?? "";
    const next = nodeB?.data?.task?.status_id ?? "";

    const currentIndex = current
      ? taskStatuses?.findIndex((item) => item.id === current)
      : Infinity;
    const nextIndex = next
      ? taskStatuses?.findIndex((item) => item.id === next)
      : Infinity;

    return (currentIndex ?? 0) - (nextIndex ?? 0);
  };
