import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchyApiArg,
} from "../../../../../services/cloudchipr.api";

import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";
import { getSavingsOpportunitiesViewsHierarchyThunk } from "../getSavingsOpportunitiesViewsHierarchyThunk";

type ReorderSavingsOpportunityViewThunkArg = Omit<
  PatchUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchyApiArg["body"],
  "index"
> & {
  index: number;
};

export const reorderSavingsOpportunityViewThunk = createAsyncThunk(
  "savingsOpportunities/reorderSavingsOpportunityView",
  async (
    { id, index }: ReorderSavingsOpportunityViewThunkArg,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentSavingsOpportunitiesHierarchy.initiate({
          body: {
            id,
            index,
            type: "view",
          },
        }),
      ).unwrap();

      if (!response) {
        return;
      }

      await dispatch(getSavingsOpportunitiesViewsHierarchyThunk());

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
