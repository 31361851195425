import { taskManagementTargetSelector } from "../common/taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { OpportunityTarget } from "../../../../../../services/cloudchipr.api";
import { TaskTargetOpportunity } from "../../../../utils/types/types";
import { isTaskManagementDrawerEditModeSelector } from "../../options/isTaskManagementDrawerEditModeSelector";
import { taskManagementTargetExistingTasksTargetIdsSelector } from "../common/taskManagementTargetExistingTasksTargetIdsSelector";

export const taskManagementTargetOpportunitiesSelector = (
  state: RootState,
): OpportunityTarget[] | undefined => {
  const target = taskManagementTargetSelector(state) as TaskTargetOpportunity;
  const resourceIdsInTasks =
    taskManagementTargetExistingTasksTargetIdsSelector(state);
  const editMode = isTaskManagementDrawerEditModeSelector(state);

  if (editMode) {
    return target?.opportunities;
  }

  return target?.opportunities?.filter(
    (opportunity) =>
      !resourceIdsInTasks?.includes(
        opportunity.provider_identifier ?? opportunity.id,
      ),
  );
};
