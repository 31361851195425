import {
  NullableEmbeddedTask,
  SavingsOpportunity,
} from "../../../../../../../../services/cloudchipr.api";

export const getExistingTasksFromOpportunities = (
  opportunities?: SavingsOpportunity[],
): Record<string, NullableEmbeddedTask> | undefined =>
  opportunities
    ?.filter((opportunity) => !!opportunity.task)
    ?.reduce(
      (acc, opportunity) => {
        acc[opportunity.resource_id ?? opportunity.id] = opportunity.task;

        return acc;
      },
      {} as Record<string, NullableEmbeddedTask>,
    );
