import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { GridApi } from "ag-grid-community";
import { ViewsHierarchy } from "../../../common/views-hierarchy-tabs/ViewsHierarchy";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { hierarchyViewIdSearchParamKey } from "../../../common/views-hierarchy-tabs/utils/constants/constants";
import { useAppAbility } from "../../../../../services/permissions";
import {
  setTaskManagementCurrentViewId,
  setTaskManagementViewCompletedShown,
} from "../../../../../store/task-management/taskManagementSlice";
import { taskManagementPathName } from "../../utils/constants/common";
import { tasksCurrentViewIdSelector } from "../../../../../store/task-management/selectors/views-hierarchy/current-view/tasksCurrentViewIdSelector";
import { deleteTasksViewLoadingSelector } from "../../../../../store/task-management/selectors/views-hierarchy/loadings/deleteTasksViewLoadingSelector";
import { updateTasksViewLoadingSelector } from "../../../../../store/task-management/selectors/views-hierarchy/loadings/updateTasksViewLoadingSelector";
import { tasksViewsHierarchyLoadingSelector } from "../../../../../store/task-management/selectors/views-hierarchy/loadings/tasksViewsHierarchyLoadingSelector";
import { createTasksViewLoadingSelector } from "../../../../../store/task-management/selectors/views-hierarchy/loadings/createTasksViewLoadingSelector";
import { useTasksViewActions } from "../../utils/hooks/useTasksViewActions";
import { tasksViewsHierarchySelector } from "../../../../../store/task-management/selectors/views-hierarchy/tasksViewsHierarchySelector";
import { getTasksViewsHierarchyThunk } from "../../../../../store/task-management/thunks/views-hierarchy/getTasksViewsHierarchyThunk";

interface TaskManagementViewsHierarchyProps {
  gridApi: GridApi | null;
}

export const TaskManagementViewsHierarchy: FC<
  TaskManagementViewsHierarchyProps
> = ({ gridApi }) => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const viewIdFromPath = searchParams.get(hierarchyViewIdSearchParamKey);

  const { cannot } = useAppAbility();
  const canNotEdit = cannot("edit", "task");

  const views = useAppSelector(tasksViewsHierarchySelector);
  const currentViewId = useAppSelector(tasksCurrentViewIdSelector);

  const createLoading = useAppSelector(createTasksViewLoadingSelector);
  const deleteLoading = useAppSelector(deleteTasksViewLoadingSelector);
  const updateLoading = useAppSelector(updateTasksViewLoadingSelector);
  const getLoading = useAppSelector(tasksViewsHierarchyLoadingSelector);

  const {
    onTabChange,
    onViewReorder,
    onViewCreate,
    onViewRename,
    onViewDelete,
    onViewDuplicate,
    onViewProtect,
  } = useTasksViewActions(gridApi);

  useEffect(() => {
    const currentView = views?.find((view) => view.id === currentViewId);
    if (currentView) {
      dispatch(
        setTaskManagementViewCompletedShown(
          !!currentView?.config?.showCompleted,
        ),
      );
    }
  }, [dispatch, currentViewId, views]);

  useDidMount(() => {
    dispatch(getTasksViewsHierarchyThunk());
  });

  useEffectOnceWhen(() => {
    dispatch(setTaskManagementCurrentViewId(viewIdFromPath ?? ""));
  }, !!viewIdFromPath);

  useEffectOnceWhen(() => {
    dispatch(setTaskManagementCurrentViewId(""));
  }, !viewIdFromPath && !!currentViewId);

  if (!views) {
    return null;
  }

  return (
    <ViewsHierarchy
      views={views}
      onTabChange={onTabChange}
      allItemsLabel="All Tasks"
      pathname={taskManagementPathName}
      actions={{
        viewCreate: {
          disabled: canNotEdit ?? getLoading,
          onSubmit: onViewCreate,
          loading: createLoading,
        },
        viewDelete: { onDelete: onViewDelete, loading: deleteLoading },
        viewDuplicate: {
          onDuplicate: onViewDuplicate,
          loading: createLoading,
        },
        viewRename: { onRename: onViewRename, loading: updateLoading },
        viewProtect: { onProtect: onViewProtect, loading: updateLoading },
        viewReorder: { onReorder: onViewReorder },
      }}
    />
  );
};
