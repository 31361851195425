import { savingsOpportunitiesDataSelector } from "./savingsOpportunitiesDataSelector";
import { RootState } from "../../store";

export const savingsOpportunitiesExistSelector = (
  state: RootState,
  opportunityId: string,
) => {
  const allOpportunities = savingsOpportunitiesDataSelector(state);

  return !!allOpportunities?.find(
    (opportunity) => opportunity.id === opportunityId,
  );
};
