import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "./liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../utils/types/types";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const liveUsageMgmtResourceTypeDataResourceByIdSelector = (
  state: RootState,
  resourceType: ResourceType,
  resourceId: string,
): ResourceDataType | null => {
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const resources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    parentResourceType ?? resourceType,
  );

  return (
    resources?.find(
      (resource) =>
        resource?.resource?.provider_unique_identifier === resourceId,
    ) ?? null
  );
};
