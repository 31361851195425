import { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";

import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ViewsHierarchyTab } from "./ViewsHierarchyTab";
import { useViewsHierarchyContext } from "./ViewsHierarchyProvider";
import { View } from "../../../../../services/cloudchipr.api";
import { ViewWithRealIndex } from "../utils/types/types";

interface ViewsHierarchyTabsProps {
  views: ViewWithRealIndex[];
  selectedViewId: string;
  onTabChange(viewId: string): void;
}

export const ViewsHierarchyTabs: FC<ViewsHierarchyTabsProps> = ({
  onTabChange,
  selectedViewId,
  views,
}) => {
  const [activeView, setActiveView] = useState<View | null>(null);
  const ids = useMemo(() => views.map((view) => view.id), [views]);

  const {
    allItemsLabel,
    actions: {
      viewReorder: { onReorder },
    },
  } = useViewsHierarchyContext();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const viewId = String(event.active.id);
      setActiveView(views.find((view) => view.id === viewId) ?? null);
    },
    [views],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      setActiveView(null);

      if (!over || active.id === over.id) return;

      const oldIndex = views.findIndex(({ id }) => id === active.id);
      const newIndex = views.findIndex(({ id }) => id === over.id);

      const realNewIndex = views[newIndex].realIndex;
      const realOldIndex = views[oldIndex].realIndex;

      onReorder(
        String(active.id),
        realNewIndex ?? newIndex,
        realOldIndex ?? oldIndex,
      );
    },
    [views, onReorder],
  );

  return (
    <Fragment>
      <ViewsHierarchyTab
        value=""
        onTabChange={onTabChange}
        selected={!selectedViewId}
        view={{ id: "", name: allItemsLabel } as View}
      />

      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
      >
        <SortableContext items={ids} strategy={horizontalListSortingStrategy}>
          {views.map((view) => (
            <ViewsHierarchyTab
              key={view.id}
              view={view}
              value={view.id}
              onTabChange={onTabChange}
              selected={selectedViewId === view.id}
            />
          ))}
        </SortableContext>

        <Box sx={overlayStyles}>
          <DragOverlay>
            {activeView && (
              <ViewsHierarchyTab
                overlayDragging
                view={activeView}
                value={activeView.id}
                selected={false}
              />
            )}
          </DragOverlay>
        </Box>
      </DndContext>
    </Fragment>
  );
};

const overlayStyles = {
  "& .MuiButtonBase-root": {
    backgroundColor: grey[100],
  },
};
