import { FC } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import {
  slackAppConnectionErrorKey,
  slackAppConnectionIdKey,
} from "../../../../utils/constants/slack-integration";

export const IntegrationsSlackAppConnection: FC = () => {
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error");
  const id = searchParams.get("integration");

  useEffectOnceWhen(() => {
    if (error) {
      localStorage.setItem(slackAppConnectionErrorKey, error);
    }

    if (id) {
      localStorage.setItem(slackAppConnectionIdKey, id);
    }

    window.close();
  }, !!id || !!error);

  return (
    <Stack
      width="100%"
      height={800}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Stack>
  );
};
