import { ViewWithRealIndex } from "../types/types";
import { View } from "../../../../../../services/cloudchipr.api";

export const getDividedViews = (
  views: View[],
  selectedViewId: string,
  fittingViewsCount: number,
) => {
  if (!fittingViewsCount) {
    return { visibleViews: [], hiddenViews: [] };
  }

  const visibleViews: ViewWithRealIndex[] = views.slice(0, fittingViewsCount);
  const hiddenViews: ViewWithRealIndex[] = views.slice(fittingViewsCount);

  const selectedIsVisible = visibleViews.some(
    (view) => view.id === selectedViewId,
  );

  if (!selectedIsVisible) {
    const selectedViewIndex = hiddenViews.findIndex(
      (view) => view.id === selectedViewId,
    );

    if (selectedViewIndex >= 0) {
      const newItemIndex = visibleViews.length - 1;
      const newItemRealIndex = newItemIndex + selectedViewIndex + 1;

      const lastVisible = visibleViews.at(-1)!;

      visibleViews[newItemIndex] = {
        ...hiddenViews[selectedViewIndex],
        realIndex: newItemRealIndex,
      };

      hiddenViews[selectedViewIndex] = lastVisible;
    }
  }

  return { visibleViews, hiddenViews };
};
