import { FC } from "react";
import { Stack } from "@mui/material";
import { AccountsHeaderProviderSelect } from "./AccountsHeaderProviderSelect";
import { AccountsTabs, AccountsTabsProps } from "../accounts/tabs/AccountsTabs";

interface AccountsHeaderNavigationProps extends AccountsTabsProps {
  accountsExist?: boolean;
}

export const AccountsHeaderNavigation: FC<AccountsHeaderNavigationProps> = ({
  accountsExist,
  ...props
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AccountsHeaderProviderSelect provider={props.provider} />
      {accountsExist && <AccountsTabs {...props} />}
    </Stack>
  );
};
