import { FC, useCallback } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { IntegrationLogo } from "./IntegrationLogo";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";
import { IntegrationType } from "../../../../../store/integrations/integrationsSlice";

interface IntegrationSelectProps {
  value: string;
  error?: string;
  type: IntegrationType;
  onChange(id: string): void;
}

export const IntegrationSelect: FC<IntegrationSelectProps> = ({
  value,
  onChange,
  error,
  type,
}) => {
  const { data: integrations } = useGetUsersMeIntegrationsQuery({
    type,
  });

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => onChange(event.target.value),
    [onChange],
  );

  const renderValue = useCallback(
    (id: string) => {
      const selected = integrations?.find((int) => int.id === id);

      if (!selected) {
        return id;
      }

      return (
        <Stack direction="row" spacing={2}>
          <IntegrationLogo type={selected.type} width={20} />
          <ListItemText>{selected.name}</ListItemText>
        </Stack>
      );
    },
    [integrations],
  );

  useEffectOnceWhen(
    () => {
      const id = integrations?.at(0)?.id;
      id && onChange(id);
    },
    integrations?.length === 1 && !value,
  );

  return (
    <FormControl size="small" fullWidth sx={{ mb: 2 }}>
      <InputLabel>Select {capitalizeString(type)} Integration</InputLabel>

      <Select
        value={value}
        onChange={changeHandler}
        renderValue={renderValue}
        label={`Select ${capitalizeString(type)} Integration`}
      >
        {integrations?.map((integration) => (
          <MenuItem key={integration.id} value={integration.id}>
            <ListItemIcon>
              <IntegrationLogo type={integration.type} width={20} />
            </ListItemIcon>

            <ListItemText>{integration.name}</ListItemText>
          </MenuItem>
        ))}
      </Select>

      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};
